import {SvgDelete} from "../../../../../assets/svg";
import React from "react";
import {IOrderOptions} from "../../../order";
import api from "../../../../../api";
import {useSnackBar} from "../../../../../context/snack-bar";
import {useDispatch} from "react-redux";
import {actionsBookings} from "../../../../../redux/reducers/bookings";
import {IOrder} from "../../../../../types/order";

export function OrderHasTable(props: IOrderOptions) {
    const dispatch = useDispatch();
    const snackBar = useSnackBar();
    const table = props.tablesList.find((item) => item.id === props.order.table);
    if (table === undefined) return null

    const deleteTable = () => {
        console.log('props.order', props.order);

        if (props.order.id !== undefined) {
            console.log('props.order.id',props.order.id);
            api.bookings.delete({id: props.order.id, token: props.token})
                .then(r => {
                    if (r.error === true) {
                        snackBar.showError(r.errorMessage);
                    } else {
                        props.setOrder({...props.order, table: null}) //Удаляем из стейда заявки
                        dispatch(actionsBookings.delete(props.order));
                    }
                })
        }

    }

    return (
        <div className="order-table-select__body has-table">
            <div className="order-table-select__table-number">{table.number} столик</div>
            <div className="order-table-select__edit" onClick={() => props.setViewPort('map')}>Изменить</div>
            <div className="order-table-select__delete" onClick={deleteTable}>
                <SvgDelete/></div>
        </div>
    )
}

export default OrderHasTable;

import React from "react";
import ViewCompanyInfo from "../@share/company-info/company-info";
import ViewOrderDate from "../@share/date/date";
import {SvgBird} from "../../../../assets/svg";

import './end-of-time.scss';

/**
 * На сегодня нет ни одной возможности забронировать
 *
 * @param props
 * @return {*}
 * @constructor
 */    // @ts-ignore
export default function ViewOrderEndOfTime(props) {
    return (
        <div className="order">
            <div className="order__title">Забронировать место</div>

            <ViewCompanyInfo {...props.companyInfo}/>

            <div className="order__body">

                <ViewOrderDate {...props}/>

                <div className="end-of-time">
                    <div className="end-of-time__image"><SvgBird/></div>
                    <div className="end-of-time__text">На сегодня забронировать уже нельзя</div>
                </div>

            </div>
            <div className="order__save-wrapper">
                <div className={"order__save is-disabled"}>Продолжить</div>
            </div>
        </div>
    )
}

import React from 'react';
import {declOfNum} from "../../utils/utils";
import {isMobile} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";
import {actionsOrder} from "../../redux/reducers/order";
import {ARRAY_GUESTS_TEXT} from "../../constants";

const MIN_GUEST_COUNTER = 2;

const ViewGuestCounter = () => {
    const order = useSelector((s: Store) => s.order);
    const companyInfo = useSelector((s: Store) => s.companyInfo);
    const dispatch = useDispatch();

    const guests: number = order.guests || MIN_GUEST_COUNTER;
    const {maxGuestCounter} = companyInfo;

    const countGuestText = guests + declOfNum(guests as number, ARRAY_GUESTS_TEXT);

    const isDisabled = order.table && order.table !== "customTable";

    const updateHandler = (type: "inc" | "dec") => () => {
        if (!isDisabled) {
            const value = type === "inc" ? guests + 1 : guests - 1;

            const newOrder = {...order, guests: (value < MIN_GUEST_COUNTER) ? MIN_GUEST_COUNTER : value};
            if (value >= maxGuestCounter) newOrder.table = "customTable";
            dispatch(actionsOrder.update(newOrder));

            if (newOrder.id !==undefined) console.log('обновить бронь на стороне сервере');
        }
    }

    return (
        <div className={`order-guest-counter ${(isDisabled) ? 'is-disabled' : ''}`}>
            <div className="order-guest-counter__wrapper">
                <div className="order-guest-counter__value">{countGuestText}</div>
                <div className="order-guest-counter__buttons">
                    {(isMobile)
                        ? <>
                            <div className="order-guest-counter__btn dec" onTouchEnd={updateHandler('dec')}/>
                            <div className="order-guest-counter__btn inc" onTouchEnd={updateHandler('inc')}/>
                        </>
                        : <>
                            <div className="order-guest-counter__btn dec" onMouseUp={updateHandler('dec')}/>
                            <div className="order-guest-counter__btn inc" onMouseUp={updateHandler('inc')}/>
                        </>
                    }
                </div>
            </div>

            {
                order.table && order.table !== "customTable" && <div className="order-guest-counter__alert">
                    Выбор количества гостей заблокирован. Чтобы снять блокировку - удалите выбранный столик
                </div>
            }

        </div>
    )
}

export default ViewGuestCounter;
import React, {useEffect, useState} from "react";
import './confirmation.scss';
import ViewConfirmationView from "./confirmation-view";
import Preloader from "../../../preloader";
import {useApiPoint} from "../../../../hooks/api-point";
import {IOrderOptions} from "../../order";
import api from "../../../../api";
import {useSnackBar} from "../../../../context/snack-bar";
import {useSelector} from "react-redux";
import {Store} from "../../../../types/redux";

// Формирование страницы подтверждения заказа
export default function ViewConfirmation(props: IOrderOptions) {
    const info = useSelector((s : Store  ) => s.companyInfo);
    const apiPoint = useApiPoint();
    const snackBar = useSnackBar();
    const {order} = props;

    const [eventInfo, setEventInfo] = useState(null);

    if (eventInfo === null) {
        api.companies.DailyEventList({
            token: props.token,
            date: order.date
        })
            .then(json => {
                if (json.error === true) {
                    snackBar.showError('Ошибка сервера #0021')
                } else {
                    setEventInfo(json.items[props.order.date]);
                }
            })
            .catch(error => snackBar.showError('Ошибка сервера #0022'));


        return <Preloader/>
    }

        function preOrder() {
            if (props.order.guests && props.order.guests >= info.maxGuestCounter) {
                props.setViewPort('complete');
            } else {
                if (props.order.deposit === 0) {
                    props.setViewPort('smsCode');
                } else {
                    const request = {
                        orderId: order.id,
                        returnUrl: window.location.href + "?action=success",
                        method: "getPaymentLink",
                        // token: props.token,
                    }

                    fetch(apiPoint + "/v2", {
                        method: 'post',
                        body: JSON.stringify(request),
                    })
                        .then(r => r.json())
                        .then(json => {
                            if (json.paymentLink.length > 0) {
                                const sberData = JSON.parse(json.paymentLink);
                                window.location.href = sberData.formUrl;
                            }
                        })
                }
            }
        }

    return <ViewConfirmationView {...props} eventInfo={eventInfo} preOrder={preOrder}/>
}

import React, {CSSProperties} from 'react';
import './style.scss'

type Props = {
    children: any;
    className?: string
    style?: CSSProperties
}

const UiBlockInfo = (props: Props) => {
    return (
        <div className={`ui-block-info tm0 ${props.className ? props.className : ""}`}
             style={props.style ? props.style : {}}>{props.children}</div>
    );
}

export default UiBlockInfo;

//@flow
import React, {useMemo, useState} from "react";
import {getAvailableTables, getDatesGrid, getMinOrderTime, getTimeGrid} from "./order-container";

import FreeTablesMode from "./views/free-tables/free-tables"; // режим открытых столов
import ViewEmpty from "./views/empty"; // Если все столики заняты
import ViewDefault from "./views/default"; // Стандартный вид заказа
import ViewInfoUser from "./views/info-user"; // Информация по заказчику
import ViewConfirmation from "./views/confirmation"; // Подтверждение информации
import ViewIsBooked from "./views/is-booked"; // Столик занят
import ViewComplete from "./views/complete"; // Столик забронирован
import type {OrderMainProps, OrderProps} from "./order-type";
import ViewSpecialEvent from "./views/special-events/special-events";
import Preloader from "../preloader";
import ViewOrderEndOfTime from "./views/end-of-time";
import ViewSmsVerification from "./views/sms-code";

import './order.scss';
import Success from "../success/success";
import moment from "moment";
import {IData} from "../../types/__old-types";
import {useSelector} from "react-redux";
import {Store} from "../../types/redux";
import {IOrder} from "../../types/order";
import {ICompanyInfo} from "../../types/company";
import {TActiveDate} from "../../types/active-date";

/**
 * Order - factory component
 *
 * @param props
 * @return {*}
 * @constructor
 * @return {null}
 */

export interface IOrderOptions extends IData {
    companyInfo: any;
    order: IOrder,
    setOrder: React.Dispatch<React.SetStateAction<IOrder>>, // экшен на заказ
    viewPort: string,
    setViewPort: React.Dispatch<React.SetStateAction<string>>, // экшен на вьюпорт
    timeGrid: any, //сетка времени
    availableTables: string[], // доступные столы
}


function Order() {
    const props: IData = {
        activeDate: useSelector((s: Store) => s.activeDate),
        arBookings: useSelector((s: Store) => s.bookings),
        companyInfo: useSelector((s: Store) => s.companyInfo),
        specialEvents: useSelector((s: Store) => s.specialEvents),
        tablesList: useSelector((s: Store) => s.tablesList),
        token: useSelector((s: Store) => s.token),
    }


    // Отображение плана
    // Если у нас выбранный день попадает на Закрытый день - ставим такой вьюпорт
    const [viewPort, setViewPort] = useState<string>(props.specialEvents[props.activeDate.activeDate] ? "specialEvent" : '');
    // @ts-ignore
    const [order, setOrder] = useState<OrderProps>({});

    // Сетка часов
    const activeDate = order.hasOwnProperty('date') ? order.date : props.activeDate;


    const currentTime = props.activeDate.currentTime;
    const workData = props.companyInfo.workdays[+moment(order.date).format('e')];
    const bookingInterval = props.companyInfo.bookingInterval;

    const timeGrid = useMemo(() => getTimeGrid({
        currentTime,
        orderDate : order.date,
        workDay: workData,
        bookingInterval
    }), [currentTime, workData, bookingInterval,order.date]);

    let url = new URL(window.location.href);
    if (url.searchParams.get('action') === 'success') return <Success {...props}
                                                                      orderId={url.searchParams.get('merchantOrderId')}/>;

    // Текущий заказ
    if (Object.entries(order).length === 0 && order.constructor === Object) {
        setOrder({
            // @ts-ignore
            time: false,
            // @ts-ignore
            duration: props.companyInfo.bookingDuration,
            date: props.activeDate.activeDate,
            guests: 2,
            table: null,
            clientName: "",
            clientPhone: "",
            comment: '',
            baseUrl: window.location.href,
        });

        return <Preloader/>
    } else {
        // @ts-ignore
        if (order.time === false && viewPort !== 'endOfTime') {
            const time = getMinOrderTime(timeGrid, props.activeDate.currentTime);
            if (time !== false) {
                setOrder({...order, time});
            } else {
                setViewPort('endOfTime');
            }
        }
    }

    /**
     * Проверка на режим открытых столов
     * Если это текущий день и режим открытых столиков включен
     * и текущее время после режима открытых столиков....
     *
     * @return {boolean}
     * @param props
     */
    function freeTablesModeCheck(props: { order: OrderProps, companyInfo: ICompanyInfo, activeDate: TActiveDate }) {

        // Проверяем чтобы заказ был на текущий день
        if (props.order.date !== props.activeDate.activeDate) return false;

        const m = moment(order.date);

        const freeTableMode = props.companyInfo.freeTableMode[+m.format('e')];

        //activeDate
        return freeTableMode.enabled && moment(props.activeDate.currentTime).isAfter(m.clone().add(freeTableMode.offset, 's'));
    }

    // Вычисляем доступные для бронирования столики
    const availableTables = getAvailableTables({
        tablesList: props.tablesList,
        order,
        bookings: props.arBookings,
    });

    // @ts-ignore
    const options: IOrderOptions = {
        ...props,
        // @ts-ignore
        order, setOrder, // экшен на заказ
        viewPort, setViewPort, // экшен на вьюпорт
        timeGrid, //сетка времени
        availableTables, // доступные столы
    };

     // Проверяем не попал ли пользователь в режим открытых столов
    const isFreeTablesMode = freeTablesModeCheck({order, companyInfo: props.companyInfo, activeDate: props.activeDate});
    if (isFreeTablesMode) return <FreeTablesMode {...options}/>;

    // Если все столики заняты
    if (availableTables.length === 0 && order.guests < props.companyInfo.maxGuestCounter && order.table === null) return <ViewEmpty {...options}/>;

    switch (viewPort) {
        case 'paymentGateWay':
            return <div>paymentGateWay</div>;
        case 'smsCode':
            return <ViewSmsVerification {...options} />;
        case 'endOfTime':
            return <ViewOrderEndOfTime {...options}/>;
        case 'complete':
            return <ViewComplete {...options}/>;
        case 'isBooked':
            return <ViewIsBooked {...options}/>;
        case 'payment':
            return <div>payment</div>;
        case 'specialEvent':
            return <ViewSpecialEvent {...options}/>;
        case 'confirmation':
            return <ViewConfirmation {...options}/>;
        case 'infoUser':
            return <ViewInfoUser {...options}/>;
        default:
            return <ViewDefault {...options}/>
    }


}

export default Order;

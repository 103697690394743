import {IOrder} from "../../types/order";

const orderNew = "orderNew";
const orderUpdate = "orderUpdate";


export const actionsOrder = {
    new : (payload : IOrder) => ({type : orderNew, payload}),
    update: (payload: IOrder) => ({type: orderUpdate, payload}),
}

const initialState = null;

export default function order(state = initialState, action: {type : string, payload : IOrder}) {

    switch (action.type) {

        case orderNew : {
            const payload = action.payload as IOrder;
            return {...payload}
        }

        case orderUpdate : {
            const payload = action.payload as IOrder;

            return {...payload};
        }

        default:
            return state;
    }
}

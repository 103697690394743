import {OrderProps} from "../components/order/order-type";
import moment from "moment";
import {dateFormat} from "../constants";
import {toFetch} from "../utils/utils";
import apiRoutes from "./apiRoutes";
import {IOrder} from "../types/order";

const bookingsApi = {
    BookingAddOverloaded: (apiPoint: string, order: IOrder, token: string) => {
        const request = {
            "method": "BookingAddOverloaded",
            token,
            "timecode": moment(order.date).add(order.time, 's').format(dateFormat.full),
            "num_guests": order.guests,
            "duration": `PT${order.duration}H`,
            "deposit": order.duration,
            "client": {
                "name": order.clientName,
                "phone": order.clientPhone,
                "comment": order.comment,
            }
        }

        return toFetch(apiPoint + '/bookings', request).then(r => r.json());
    },


    /**
     * Удаление брони
     */
    delete: (data: { token: string, id: string }) => (toFetch(apiRoutes.bookings, {
        ...data,
        method: "BookingDelete"
    })).then(r => r.json()),

    /**
     * Обновление данных о брони
     * @param order
     * @param token
     */
    update: (order: IOrder, token: string) => {
        const req = {
            ...order,
            "method": "BookingEdit",
            token,
        };

        return toFetch(apiRoutes.bookings, req)
            .then(r => r.json())
    }
}

export default bookingsApi;

import {IBookings} from "../../types/bookings";
import {IOrder} from "../../types/order";
import {OrderProps} from "../../components/order/order-type";

const setBookings = "setBookings";
const bookingsDelete = "bookingsDelete";


export const actionsBookings = {
    add: (payload: any) => ({type: setBookings, payload}),
    delete : (payload : IOrder) => ({type : bookingsDelete, payload}),
}

const initialState: {[date : string] : IOrder[]} = {};

export default function bookings (state = initialState, action: any) {

    switch (action.type) {

        case bookingsDelete : {
            const order = action.payload as IOrder;

            return {...state , [order.date] : state[order.date].filter(i=> i.id !== order.id)}
        }

        case setBookings :
            return {...action.payload};
        default:
            return state;
    }
}

import React, {useState} from "react";
import {SmsCodeSuccessView} from "./success-view";
import {toFetch} from "../../../../../utils/utils";    // @ts-ignore
import * as pbkdf2 from "pbkdf2";
import {useApiPoint} from "../../../../../hooks/api-point";
import {useSnackBar} from "../../../../../context/snack-bar";
import api from "../../../../../api";
// @ts-ignore
export default function SmsCodeSuccess(props) {
    const apiPoint = useApiPoint();
    const snackBar = useSnackBar();

    const [fieldCode, setFieldCode] = useState('');

    /**
     * Проверка кода с тем, что пришел с сервера
     *
     * @returns {boolean}
     */
    function verifyCode() {
        const derivedKey = pbkdf2.pbkdf2Sync(fieldCode, props.verify.salt, 1000, 32, 'sha512');
        return derivedKey.toString('hex') === props.verify.code;
    }

    /**
     * Завершаем бронирование
     */
    function completeBooking() {
        api.bookings.update({...props.order, status : "booked"}, props.token)
            .then(json => {
                if (json.error === true) {
                    console.error(json.errorMessage)
                    snackBar.showError('Ошибка #0014 ' + json.errorMessage)
                }

                if (json.error === false) {
                    props.setViewPort('complete');
                }
            })
            .catch((error) => snackBar.showError('Ошибка сервера #0015'));

    }

    const isValidate = verifyCode();

    const options = {
        ...props,
        isValidate, // Прошел ли ввод кода проверку
        fieldCode, setFieldCode, // Поле ввода
        completeBooking,
    };

    return <SmsCodeSuccessView {...options}/>
}

import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import OrderComplete from "../components/order-complete";
import siteRoutes from "../constants/siteRoutes";
import Order from "../components/order";
import PaySuccess from "../pages/pay-success";
import {useSelector} from "react-redux";
import {Store} from "../types/redux";
import Preloader from "../components/preloader";
import OrderPage from "../pages/order";

const AppRouter = () => {

    const activeDate = useSelector((s: Store) => s.activeDate);
    const arBookings = useSelector((s: Store) => s.bookings);
    const companyInfo = useSelector((s: Store) => s.companyInfo);
    const specialEvents = useSelector((s: Store) => s.specialEvents);
    const tablesList = useSelector((s: Store) => s.tablesList);
    const tablePricesByDay = useSelector((s : Store) => s.tablePricesByDay);
    const token = useSelector((s: Store) => s.token);



    if (!activeDate ||
        !tablePricesByDay ||
        arBookings[activeDate.activeDate] === undefined ||
        !companyInfo ||
        !specialEvents ||
        !tablesList ||
        !token) return <Preloader/>

    return (
        <Router>
            <Switch>
                <Route path={siteRoutes.paySuccess} component={PaySuccess}/>
                <Route path={siteRoutes.newOrder} component={OrderPage} />
                <Route path={siteRoutes.orderComplete}>
                    <OrderComplete/>
                </Route>
                <Route exact path={siteRoutes.home}>
                    <Order/>
                </Route>
            </Switch>
        </Router>
    )
}

export default AppRouter

import React, {useState} from "react";
import Preloader from "../preloader";
import {declOfNum, toFetch} from "../../utils/utils";
import {SvgSuccesOrder} from "../../assets/svg";
import moment from "moment";
import {useApiPoint} from "../../hooks/api-point";
import {useSnackBar} from "../../context/snack-bar";
// @ts-ignore
export default function Success(props) {
    const apiPoint = useApiPoint();
    const snackBar = useSnackBar();

    const [data, setData] = useState(false);

    if (!data) {
        const request = {
            method: 'BookingGetByID',
            token: props.token,
            id: props.orderId,
        }

        toFetch(apiPoint + '/bookings', request).then(r => r.json())
            .then(json => {

                if (json.error === true) {
                    console.error(json.errorMessage);
                    snackBar.showError('Ошибка #17')
                }

                if (json.error === false) {
                    setData(json.item);
                }
            })
            .catch(error => snackBar.showError('Ошибка сервера #18'))


        return <Preloader/>
    }
    // @ts-ignore
    const m = moment(data.duration.date_start);

    const text2 = '';
    const dateText = m.format('DD.MM.YYYY');
    const dateTime = m.format("HH:mm");    // @ts-ignore
    const duration = moment(data.duration.date_end).diff(m, "h",true);
    // @ts-ignore
    const numOfGuests = data.num_guests + declOfNum(data.num_guests, [" гость", ' гостя', ' гостей']);

    return (
        <div className='complete-order'>
            <div className="complete-order__header">
                <div className="complete-order__icon">
                    <SvgSuccesOrder/>
                </div>
                <div className="complete-order__text-1">
                    Столик забронирован
                </div>
                <div className="complete-order__text-2">{text2}</div>
            </div>

            <div className="complete-order__body">
                <div className="company-info">
                    <div className="company-info__name">{props.companyInfo.name}</div>
                    <div className="company-info__address">{props.companyInfo.address}</div>
                </div>

                <div className="order">
                    <div className="order__item">
                        <div>Дата</div>
                        <div className="value">{dateText}</div>
                    </div>
                    <div className="order__item">
                        <div>Время</div>
                        <div className="value">{dateTime} (бронируется на {duration} часа)</div>
                    </div>
                    <div className="order__item">
                        <div>Гости</div>
                        <div className="value">{numOfGuests}</div>
                    </div>
                    <div className="order__item" style={{marginBottom: '20px'}}>
                        <div>Столик</div>
                        <div className="value">{    // @ts-ignore
                            data.table.number}</div>
                    </div>
                    <div className="order__item">
                        <div>Имя</div>
                        <div className="value">{    // @ts-ignore
                            data.client.name}</div>
                    </div>
                    <div className="order__item" style={{marginBottom: '20px'}}>
                        <div>Телефон</div>
                        <div className="value">{    // @ts-ignore
                            data.client.phone}</div>
                    </div>

                    <div className="order__item">
                        <div>Депозит</div>
                        <div className="value">{    // @ts-ignore
                            data.deposit} ₽</div>
                    </div>
                </div>

            </div>
        </div>
    )
}

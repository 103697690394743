import moment from "moment";
import {OrderProps} from "./order-type";
import {ITable} from "../../types/table";
import {IBookingInfo} from "../../types/bookings";
import {IWorkDay} from "../../types/company";
import {dateFormat} from "../../constants";
import {ISpecialEvent, ISpecialEvents} from "../../types/special-events";

export type GetDatesGrid = Array<null | { title: string, value: string , event?: ISpecialEvent, currentDay : boolean}>;


/**
 * Формируем сетку на 30 дней от текущей даты
 * @param activeDate
 */
export const getDatesGrid = (activeDate: string, specialEvents : ISpecialEvents = {}) => {
    const result: GetDatesGrid = [];

    const mActiveDate = moment(activeDate).startOf('day');
    const m = mActiveDate.clone();

    //Добавляем фэйковые даты ДО текущей даты
    for (let i = 0; i < +m.format('e'); i++) result.push(null);

    for (let i = 0; i < 30; i++) {

        const day = (i === 0) ? m : m.add(1, 'd');

        const value = day.format(dateFormat.short);

        result.push({
            title: day.format('DD'),
            value,
            event: specialEvents[value],
            currentDay : m.isSame(mActiveDate),
        })

    }

    // Добиваем фэйки до конца недели
    const countLastDates = 7 - (result.length % 7);

    result.length = result.length + countLastDates;

    return result;
}

// export const getDatesGrid = (props: IData) => {
//     let result : Array<null | {title : string , value : string}> = [];
//     const m = moment(props.activeDate.activeDate).startOf('day');
//     const dateOfWeek = m.format('e');
//
//     //Добавляем фэйковые даты ДО текущей даты
//     // @ts-ignore
//     for (let i = 0; i < dateOfWeek; i++) {
//         result.push({});
//     }
//
//     // Вычисляем 14 дней вперед
//     for (let i = 0; i < 30; i++) {
//
//         const day = (i === 0) ? m : m.add(1, 'd');
//
//         result.push({
//             title: day.format('DD'),
//             value: day.format(dateFormat.short),
//         })
//     }
//
//     // Добиваем фэйки до конца недели
//     const countLastDates = 7 - (result.length % 7);
//
//     if (countLastDates < 7) {
//         for (let i = 0; i < countLastDates; i++) {
//             result.push({});
//         }
//     }
//
//     return result;
// };

/**
 * Формируем сетку дня заказа по времени
 */

type GetTimeGrid = {
    currentTime: string, // Текущее время
    orderDate: string, // День заявки
    workDay: IWorkDay, //Данные о рабочем дне
    bookingInterval: number, // Интервал бронирования
}

type GetTimeGridResult = {
    isDisabled: boolean,
    offset: number,
    rfc: string,
    timestamp: string,
    value: string,
}

export const getTimeGrid = (props: GetTimeGrid) => {
    const result: GetTimeGridResult[] = [];

    const mCurrentTime = moment(props.currentTime);
    const mDate = moment(props.orderDate).clone().startOf('day');
    const mStartWorkTime = mDate.clone().add(props.workDay.start_time, 's');
    const mTime = mStartWorkTime.clone();
    const mEndWorkTime = mDate.clone().add(props.workDay.end_time, 's');
    const tenHours = mDate.clone().add(22, 'h');

    while (mTime.isBefore(mEndWorkTime)) {

        const item: GetTimeGridResult = {
            value: mTime.format("HH:mm"),
            timestamp: mTime.format('x'),
            rfc: mTime.format(),
            offset: mTime.diff(mDate, 'seconds'), // Сдвиг от начала текущего дня в секундах
            isDisabled: mTime.isBefore(mCurrentTime) || mTime.isAfter(tenHours)// Если больше 22 часов - брокируем возможность забронировать
        };

        result.push(item);

        mTime.add(props.bookingInterval, 's'); // ОБЯЗАТЕЛЬНО!!!
    }

    return result;
};

// @ts-ignore
export function getMinOrderTime(timeGrid, currentTime) {
    const curr = moment(currentTime);
    for (let time of timeGrid) {
        if (moment(time.rfc) > curr) return time.offset;
    }
    return false;
}


/**
 * Список доступных столов
 *
 * @return {*[]} - Массив id доступных столов
 */
// @ts-ignore

type  TGetAvailableTables = {
    order: OrderProps,
    tablesList: ITable[],
    bookings: { [key: string]: IBookingInfo[] },
}

export function getAvailableTables(props: TGetAvailableTables) {
    const result: string[] = [];
    const mDate = moment(props.order.date);
    const dayOfWeek = parseInt(mDate.format('e'));
    const mOrderStart = mDate.clone().add(props.order.time, 's');
    const mOrderEnd = mOrderStart.clone().add(props.order.duration, 'h');

    // #####################
    // # --- Замыкания --- #
    // #####################

    /**
     * Выбираем столы на которые мы можем посадить указанное число гостей
     */
    const getTablesByPersons = (tables: ITable[]) => {
        const result = [];
        for (let table of tables) {
            if (props.order.guests >= table.persons.from && props.order.guests <= table.persons.to) {
                result.push(table);
            }
        }
        return result;
    }

    /**
     * Отсеиваем столы которые заняты в текущий день
     * @param tables
     */
    const getFreeTables = (tables: ITable[]) => {
        const result = [];
        const bookingInDay = props.bookings[props.order.date];

        for (let table of tables) {
            const bookings = bookingInDay.filter((booking) => booking.tableID === table.id);

            let tableIsFree = true;

            // Проверяем занятость стола
            for (let booking of bookings) {
                if (moment(booking.dateStart).isBefore(mOrderEnd)) {
                    tableIsFree = false;
                    break;
                }

                if (moment(booking.dateEnd).isBefore(mOrderStart)) {
                    tableIsFree = false;
                    break;
                }
            }


            if (tableIsFree) result.push(table);


        }

        return result;
    }

    /**
     * Превращаем массив столов в массив id столов
     * @param tables
     */
    const toArrayOfIds = (tables: ITable[]) => {
        const result = [];

        for (let table of tables) {
            result.push(table.id)
        }

        return result;
    }

    // #####################
    // # --- Логика --- #
    // #####################

    const tablesByPersons = getTablesByPersons(props.tablesList);

    let freeTables = getFreeTables(tablesByPersons)

    // TODO Костыль что столы 24 25 пользователь не может забронировать в пятницу или субботу для Пивной №1
    if (process.env.REACT_APP_COMPANY_ID === "5dea62d620e91a13c99c0075" && (dayOfWeek === 4 || dayOfWeek === 5)) {
        freeTables = freeTables.filter((table: ITable) => !(table.number === 24 || table.number === 25));
    }

    return toArrayOfIds(freeTables);
}



import {toFetch} from "../utils/utils";
import apiRoutes from "./apiRoutes";
import {actionsToken} from "../redux/reducers/token";

const tokenName = process.env.REACT_APP_LOCALSTORAGE_TOKEN_NAME ? process.env.REACT_APP_LOCALSTORAGE_TOKEN_NAME : "";

export const apiUser = {
    //Получение токена
    getToken: () => ((dispatch: any) => {
            const body = {
                "method": 'authorize',
                'company': process.env.REACT_APP_COMPANY_ID,
            };

            toFetch(apiRoutes.root, body).then(r => r.json())
                .then(json => {
                    if (json.error) {
                        console.error(" Ошибка получения токена")
                    } else {
                        dispatch(actionsToken.add(json.token));
                        localStorage.setItem(tokenName, json.token)
                    }
                })
                .catch(error => console.error('Ошибка сервера'));
        }
    ),

    //Проверка токена
    UserWhoami: (token: string) => ((dispatch: any) => {
        const request = {"method": "UserWhoami", token};

        toFetch(apiRoutes.users, request).then(r => r.json())
            .then(json => {
                if (json.error) {
                    localStorage.removeItem(tokenName)
                    dispatch(apiUser.getToken())
                } else {
                    dispatch(actionsToken.add(token));
                    localStorage.setItem(tokenName, token)
                }
            })
            .catch(error => console.error('Ошибка сервера'));
    }),
};

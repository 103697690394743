import React from 'react';
import {SvgArrowLeft, SvgInfo} from "../../../../assets/svg";
import EventInfo from "./event-info";
import moment from "moment";
import {declOfNum} from "../../../../utils/utils";
import {ARRAY_GUESTS_TEXT} from "../../../../constants";
import UiBlockInfo from "../../../ui/info-block";
import {useSelector} from "react-redux";
import {Store} from "../../../../types/redux";

// Надо нормально перевести проект на global storage - это полнейший лапшекод
// Так как проект небольшой - скорее всего буду использовать MobX - он более лаконичен для него
// type Props = {
//     activeDate: string,
//     arBookings: any[],
//     availableTables: string[],
//     companyInfo: any,
//     currentTime: string,
//     datesGrid: any[],
//     eventInfo: any
//     order: IOrder,
//     preOrder: any,
//     setOrder: any,
//     setViewPort: any,
//     specialEvents: {}
//     tablesList: any[],
//     timeGrid: any[],
//     token: string,
//     viewPort: string,
// }

type OrderInfo = { label: string, value: string, marginBottom?: number }[]

export default function ViewConfirmationView(props: any) {
    const companyInfo = useSelector((s : Store  ) => s.companyInfo);
    const mOrder = moment(props.order.date).add(props.order.time, 's');

    const dateText = `${mOrder.clone().startOf('day')
        .isSame(moment(props.activeDate)) ? "Сегодня • " : ""}${mOrder.format('DD MMM, dddd')}`;

    const orderInfo: OrderInfo = [
        {label: "Дата", value: dateText},
        {label: "Время", value: `${mOrder.format('HH:mm')} (бронируется на ${props.order.duration} часа)`},
        {label: "Гости", value: props.order.guests + declOfNum(props.order.guests, ARRAY_GUESTS_TEXT)},
        {
            label: "Столик",
            value: (props.order.table === 'customTable') ? "из нескольких столиков" : props.tablesList.find((item: any) => item.id === props.order.table).number,
            marginBottom: 20,
        },
        {label: "Имя", value: props.order.clientName},
        {label: "Телефон", value: props.order.clientPhone, marginBottom: 20},
    ]

    return (
        <div className="view">
            <div className="view__header">
                <div className="icon" onClick={(() => props.setViewPort('infoUser'))}><SvgArrowLeft/></div>
                <div className="title">Подтвердите бронь</div>
            </div>

            <div className="view__body order-confirmation">
                <div className="company-info">
                    <div className="company-info__name">{props.companyInfo.name}</div>
                    <div className="company-info__address">{props.companyInfo.address}</div>
                </div>

                <div className="order   ">
                    {orderInfo.map((item, key) => {
                        return (
                            <div
                                className={`order__item ${item.marginBottom !== undefined ? `mb-${item.marginBottom}` : ""}`}
                                key={key}>
                                <div>{item.label}</div>
                                <div className="value">{item.value}</div>
                            </div>
                        )
                    })}

                    {props.order.table !== 'customTable' &&
                    <div>
                        <div className="order__item">
                            <div>К оплате</div>
                            <div className="price">
                                {(props.order.deposit > 0) ? props.order.deposit + ' ₽' : 'Без депозита'}
                            </div>
                        </div>
                        {props.order.deposit > 0 &&
                        <>
                            <UiBlockInfo>
                                Для подтверждения бронирования необходимо внести депозит.<br/>
                                Обращаем Ваше внимание, что депозит возвращается только в случае отмены бронирования.

                                {companyInfo.confirmationCustomText && <div><b>{companyInfo.confirmationCustomText}</b></div>}
                            </UiBlockInfo>
                        </>
                        }
                    </div>
                    }

                    <EventInfo {...props.eventInfo}/>
                </div>
            </div>
            <div className="view__footer">
                <div className="btn btn--primary" onClick={() => props.preOrder()}>Продолжить</div>
            </div>
        </div>
    )
}




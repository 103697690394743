import React from "react";
import {SvgArrowLeft} from "../../../../assets/svg";    // @ts-ignore
import InputMask from 'react-input-mask';
import './info-user.scss';
import {toFetch} from "../../../../utils/utils";
import {useApiPoint} from "../../../../hooks/api-point";
import {useSnackBar} from "../../../../context/snack-bar";
import api from "../../../../api";
import moment from "moment";
import {useHistory} from 'react-router-dom';
import siteRoutes from "../../../../constants/siteRoutes";
import {IOrderOptions} from "../../order";
import {dateFormat} from "../../../../constants";

// @ts-ignore
export default function ViewInfoUser(props: IOrderOptions) {
    const apiPoint = useApiPoint();
    const snackBar = useSnackBar();
    const history = useHistory();
    const isDisabled = (props.order.clientName === '' || props.order.clientPhone === '') ? 'is-disabled' : '';

    let [name, surname] = props.order.clientName ? props.order.clientName.split(' ') : ["", ""];

    if (surname === undefined) surname = '';

    // @ts-ignore
    function updateClientName(name, surname) {
        if (surname !== '') name = name + ' ' + surname;
        props.setOrder({...props.order, clientName: name});
    }

    function nextHandler() {
        if (isDisabled === '') {
            // Если количество гостей больше максимального
            if (props.order.guests && props.order.guests >= props.companyInfo.maxGuestCounter) {
                api.bookings.BookingAddOverloaded(apiPoint, props.order, props.token)
                    .then((json) => {
                        if (json.error) {
                            snackBar.showError(json.errorMessage)
                        } else {
                            const key = moment(props.order.date).add(props.order.time, 's').format(dateFormat.full);
                            localStorage.setItem('order_' + key, JSON.stringify(props.order));
                            history.push(siteRoutes.orderComplete.replace(':orderId', key))
                        }
                    })
                    .catch(error => snackBar.showError('Ошибка сервера #0021'))
            } else {
                api.bookings.update(props.order, props.token)
                    .then(json => {
                        if (json.error === true) {
                            console.error(json.errorMessage);
                            snackBar.showError('Ошибка #0010')
                        }
                        if (json.error === false) props.setViewPort('confirmation');
                    })
                    .catch(error => snackBar.showError('Ошибка сервера #0011'));
            }
        }
    }

    return (
        <div className="view">
            <div className="view__header">
                <div className="icon" onClick={(() => props.setViewPort(''))}><SvgArrowLeft/></div>
                <div className="title">Заполните данные</div>
            </div>
            <div className="view__body order-info">
                <div className="form-control">
                    <input type="text" id="OrderName" value={name} name="FirstName"
                           onChange={e => updateClientName(e.target.value, surname)}/>
                    <label htmlFor="OrderName">Имя</label>
                </div>
                <div className="form-control">
                    <input type="text" id="OrderSurname" value={surname} name="LastName"
                           onChange={e => updateClientName(name, e.target.value)}/>
                    <label htmlFor="OrderSurname">Фамилия</label>
                </div>

                <div className="form-control --phone">
                    <InputMask id="OrderPhone" mask="+7 (999) 999-99-99" value={props.order.clientPhone} name="Phone"
                               onChange={(e: any) => props.setOrder({...props.order, clientPhone: e.target.value})}/>
                    <label htmlFor="OrderPhone">Телефон</label>
                </div>

            </div>

            <div className="view__footer">
                <div className={"btn btn--primary " + isDisabled} onClick={() => nextHandler()}>Продолжить</div>
            </div>
        </div>
    )
}

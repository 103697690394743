import React from "react";
import moment from "moment";

export function FreeTablesModeInfo(props : any) {
    const phoneLink = props.companyInfo.phone.replace(/[\s-\(\)]/ig,'');

    const daysOfWeek =  +moment(props.order.date).format('e');

    const time = moment(props.order.date).startOf('day').add(props.companyInfo.freeTableMode[daysOfWeek].offset,'s').format('HH:mm');
    return (
        <div className="free-tables-mode-info">
            {/*<div className="free-tables-mode-info__title">*/}
            {/*    Режим открытых столиков*/}
            {/*</div>*/}
            <div className="free-tables-mode-info__text">
                После <span>{time}</span> нельзя забронировать столик через сервис. Вы можете забронировать столик по телефону: <a href={`tel:${phoneLink}`} style={{color:'black',}}><b>{props.companyInfo.phone}</b></a> или прийти и занять свободное место.
            </div>
        </div>
    )
}

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";
import api from "../../api";

const TablesList = ()=> {
    const dispatch = useDispatch();
    const token = useSelector((s: Store) => s.token);
    const tablesList = useSelector((s: Store) => s.tablesList)

    useEffect(() => {
        if (tablesList.length === 0 && token) dispatch(api.companies.GetTablesList(token))
    }, [dispatch, tablesList, token])

    return null;
}

export default TablesList;

import React from 'react'
import OrderHasTable from "../order/views/@share/table/order-has-table";
import OrderNotHasTable from "../order/views/@share/table/order-not-has-table";
import {useSelector} from "react-redux";

const ViewTableSelector = ()=>{
    // const order = useSelector()
    //
    //
    // if (props.order.guests && props.order.guests >= props.companyInfo.maxGuestCounter) {
    //     return (
    //         <div className="order-custom-table">
    //             К сожалению, по вашему запросу свободных столиков не найдено, вы можете оставить запрос на бронирование
    //             и с вами свяжется администратор в ближайшее доступное время
    //         </div>
    //     )
    // }
    //
    // const available = props.availableTables.length;

    return (
        <div className="order-table-select">
            {/*<div className="order-table-select__header">*/}
            {/*    <div className="order-table-select__title">Столик</div>*/}
            {/*    <div className="order-table-select__available">{available} свободно</div>*/}
            {/*</div>*/}
            {/*{*/}
            {/*    props.order.table !== null*/}
            {/*        ? <OrderHasTable {...props}/>*/}
            {/*        : <OrderNotHasTable {...props}/>*/}
            {/*}*/}
        </div>
    )
}

export default ViewTableSelector;
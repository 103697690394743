import React from "react";
import {SvgArrowLeft} from "../../../../../assets/svg";
// @ts-ignore
export default function SmsCodeErrorView(props){

    return (props.error.errorMessage === 'Rate limit exceeded!') ? <FastRe {...props}/> : <ErrorPhone {...props} />
}
// @ts-ignore
function FastRe(props){
    return (
        <div className="view">
            <div className="view__header">
                <div className="icon" onClick={(() => props.setViewPort('confirmation'))}><SvgArrowLeft/></div>
                <div className="title">Ошибка</div>
            </div>

            <div className="view__body notify text-center">
                Вы уже отправляли смс. Попробуйте получить код через 1 минуту.
            </div>
            <div className="view__footer">
                <div className="btn btn--primary" onClick={() => {props.setViewPort('confirmation')}}>Назад</div>
            </div>
        </div>
    )
}
// @ts-ignore
function ErrorPhone(props) {
    return (
        <div className="view">
            <div className="view__header">
                <div className="icon" onClick={(() => props.setViewPort('confirmation'))}><SvgArrowLeft/></div>
                <div className="title">Ошибка</div>
            </div>

            <div className="view__body notify text-center">
                <div>Возможно вы ввели неправильный номер телефона</div>
                <div><b>{props.order.clientPhone}</b></div>
            </div>
            <div className="view__footer">
                <div className="btn btn--primary" onClick={() => props.setViewPort('infoUser')}>Изменить номер</div>
            </div>
        </div>
    )
}

import React from "react";
import {SvgBird} from "../../../../assets/svg";

import './is-booked.scss';
// @ts-ignore
export default function ViewIsBooked(props){
    return(
        <div className='is-booked'>
            <div className="is-booked__container">
                <div className="is-booked__icon"><SvgBird/></div>
                <div className="is-booked__text">
                    К сожалению, выбранный столик уже забронировали
                </div>
                <div className="is-booked__btn btn btn--primary" onClick={()=>props.setViewPort('')}>Выбрать другой вариант</div>
            </div>
        </div>
    )
}

const setToken = "setToken";
const removeToken = "removeToken";

export const actionsToken = {
    add : (payload : string)=>({type : setToken, payload}),
    remove : () => ({type : removeToken})
}

const initialState = null;

export default function token(state = initialState, action : any) {
    switch (action.type) {
        case removeToken :
            return null;
        case setToken :
            return action.payload;
        default:
            return state;
    }
}

import React from "react";

import './company-info.scss';

export default function ViewCompanyInfo(props : any){
    return (
        <div className="company-info">
            <div className={`company-info__icon ${props.detailPicture ==="" ? "" : "is-bg-transparent"}`}
                 style={{backgroundImage: `url(${props.detailPicture})`}}
            />
            <div className="company-info__text">
                <div className="company-info__title">{props.name}</div>
                <div className="company-info__address">{props.address}</div>
            </div>
        </div>
    )
}

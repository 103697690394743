import {getRandomInt} from "../../../../../utils/utils";
import moment from "moment";
import React from "react";
import {useSelector} from "react-redux";
import {Store} from "../../../../../types/redux";
import {IOrder} from "../../../../../types/order";
import api from "../../../../../api";
import {useSnackBar} from "../../../../../context/snack-bar";

export default function OrderNotHasTable(props: any) {
    const order: IOrder = props.order;
    const tablePricesByDay = useSelector((s: Store) => s.tablePricesByDay[props.order.date]);
    const snackBar = useSnackBar();
    const isDisabled = !props.order.date || !props.order.time;

    /**
     * Выбираем случайный столик из доступных
     */

    const mapClickHandler = ()=>{
        if (!isDisabled) {
            props.setViewPort('map');
        }
    }

    function getRandomTable() {
        if (!isDisabled) {
            const {availableTables} = props;

            const index = getRandomInt(availableTables.length);

            const tableId = availableTables[index];
            const dayOfWeek = moment(order.date).format('e');

            const currentTable = props.tablesList.filter((item: any) => item.id === tableId)[0];

            const customPrice = tablePricesByDay && tablePricesByDay.find((T) => T.table === tableId);
            const deposit = customPrice ? customPrice.price : currentTable.defaultDeposit[dayOfWeek];

            order.table = tableId;
            order.deposit = deposit;

            api.bookings.update(order, props.token)
                .then(r => {
                    if (r.error) {
                        snackBar.showError(r.errorMessage)
                    } else {
                        props.setOrder(r.result);
                    }
                });
        }
    }

    return (
        <div className="order-table-select__body">
            <div className="order-table-select__btn" onClick={mapClickHandler}>Указать на карте</div>
            <div className="order-table-select__btn" onClick={getRandomTable}>Выбрать любой</div>
        </div>
    )
}

import React from "react";
import moment from "moment";
import api from "../../../../../api";
import {useSelector} from "react-redux";
import {Store} from "../../../../../types/redux";

/**
 * Сетка времени
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function ViewOrderTime(props: any) {
    const currentTime = moment(props.currentTime).format('x');
    const token = useSelector((s : Store) => s.token);

    const setTime = (time: number) => {
        //Так как пока на бэке заказы лежат в столах и не возможности создавать заказ без ID стола - фактически удаяем текущий order
        if (props.order.id !== undefined) api.bookings.delete({token, id : props.order.id});

        props.setOrder({...props.order, time, table: undefined, id : undefined})
    }

    return (
        <div className="order-time">
            <div className="order-time__title">Время</div>
            <ul>
                {
                    // @ts-ignore
                    props.timeGrid.map(item => {
                        const isActive = (item.offset === props.order.time) ? 'is-active' : '';

                        if (currentTime > item.timestamp || item.isDisabled)
                            return <li className={'is-disabled ' + isActive} key={item.value}><span>{item.value}</span>
                            </li>
                        else {
                            return <li className={isActive} key={item.value} onClick={() => setTime(item.offset)}>
                                <span>{item.value}</span>
                            </li>
                        }
                    })}
            </ul>
        </div>
    )
}

import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";
import api from "../../api";

/**
 * Получение данных о специальных событиях компании
 * @constructor
 */
const SpecialEvents = () => {
    const dispatch = useDispatch();
    const token = useSelector((s: Store) => s.token);
    const currentDate = useSelector((s: Store) => s.activeDate?.activeDate);
    const specialEvents = useSelector((s: Store) => s.specialEvents)

    useEffect(() => {
        if (specialEvents === null && token && currentDate) dispatch(api.companies.GetSpecialEvents(token, currentDate))
    }, [dispatch, specialEvents, token, currentDate])

    return null;
}

export default SpecialEvents;

/** Проверяем не мертвый ли токен, если да - удаляем данные из sessionStorage и перегружаем страницу*/
export function isTokenCheck(json:  any) {
    if (json.error && json.errorMessage === 'Wrong token. Get a new one.') {
        localStorage.removeItem('token');
        window.location.reload();
    }
}

/**
 * Немного оберки над fetch
 *
 * @param url
 * @param body
 * @return {Promise<Response>}
 */
export function toFetch(url : string, body : any) {
    return fetch(url, {
        method: 'post',
        // mode: "no-cors",
        body: JSON.stringify(body),
    });
}


/**
 * Склонение числительных
 *
 * @param number - число
 * @param titles - массив склонений из 3-х вариантов
 * @return {*}
 */
export const declOfNum = (number : number, titles : any) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};


/**
 * Проверка что объект пустой ( ECMA 7+ )
 *
 * @param obj
 * @return {boolean|boolean}
 */
export const isEmptyObject = (obj : any) => {
    return (Object.entries(obj).length === 0 && obj.constructor === Object);
}

export function getRandomInt(max : any) {
    return Math.floor(Math.random() * Math.floor(max));
}

import React, {SVGProps, TouchEventHandler, useState} from "react";
import {declOfNum} from "../../../../utils/utils";
import {getLength, getCenterTouches} from './map-container';
import moment from "moment";
import Preloader from "../../../preloader";
import {useSelector} from "react-redux";
import {Store} from "../../../../types/redux";

type SvgProps = {
    height: number,
    width: number,
    scale: number,
    scaleModyfier: number,
    basePoint: number[],
    maxBasePoint: number[],
    length: number,
    center: number[],
    moveStart: number[] | null,
    oldBasePoint?: number[],
}

export default function ViewMap(props: any) {
    const [svg, setSvg] = useState<SvgProps>({
        height: (window.innerHeight - 110),
        width: window.innerWidth,
        scale: 1,
        scaleModyfier: 0.7,
        basePoint: [0, 0],
        maxBasePoint: [0, 0],
        length: 1,
        center: [0, 0],
        moveStart: null,
    });

    function touchStart(e: any) {
        const touch1 = e.touches[0],
            touch2 = e.touches[1];

        if (touch1 && touch2) {
            const length = getLength(touch1, touch2);
            const center = getCenterTouches(touch1, touch2);

            setSvg({...svg, length, center, moveStart: null});
        } else if (touch1) {
            setSvg({...svg, moveStart: [touch1.pageX, touch1.pageY], oldBasePoint: svg.basePoint});
        }
    }

    function touchMove(e: any) {
        const touch1 = e.touches[0],
            touch2 = e.touches[1];

        if (touch1 && touch2) zoom(touch1, touch2);
        else if (touch1) move(touch1);
    }

    function move(touch1: any) {
        if (svg.moveStart) {
            const delta = [
                // @ts-ignore
                (svg.moveStart[0] - touch1.pageX),
                // @ts-ignore
                (svg.moveStart[1] - touch1.pageY),
            ];

            const basePoint = [
                // @ts-ignore
                svg.oldBasePoint[0] + delta[0],
                // @ts-ignore
                svg.oldBasePoint[1] + delta[1],
            ];

            // проверяем чтобы точка сдвига была не дальше левого края карты
            basePoint[0] = basePoint[0] < 0 ? 0 : basePoint[0];
            basePoint[1] = basePoint[1] < 0 ? 0 : basePoint[1];

            // проверяем чтобы точка сдвига была не дальше правого края карты
            basePoint[0] = basePoint[0] > svg.maxBasePoint[0] ? svg.maxBasePoint[0] : basePoint[0];
            basePoint[1] = basePoint[1] > svg.maxBasePoint[1] ? svg.maxBasePoint[1] : basePoint[1];

            setSvg({...svg, basePoint});
        }
    }

    function zoom(touch1: any, touch2: any) {
        const length = getLength(touch1, touch2);

        const scaleValue = svg.scale * length / svg.length;

        const scale = scaleValue > 4
            ? 4
            : scaleValue < 1 ? 1 : scaleValue;

        const basePoint = [
            svg.center[0] * scale - svg.center[0],
            svg.center[1] * scale - svg.center[1],
        ];

        const maxBasePoint = [
            svg.width * scale - svg.width,
            svg.height * scale - svg.height,

        ];

        const opts = {...svg, scale, basePoint, maxBasePoint};

        if (Math.abs(length - svg.length) > 10) {
            opts.length = length;
        }
        setSvg(opts);
    }

    const viewBox = `${svg.basePoint.join(' ')} ${svg.width} ${svg.height}`;

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             id="restaurantMap"
             width={svg.width}
             height={svg.height}
             viewBox={viewBox}
             onTouchStart={touchStart}
             onTouchMove={touchMove}
        >
            <g transform={`scale(${svg.scale})`}>
                <SvgGroup {...props} width={svg.width} height={svg.height}/>
            </g>
        </svg>
    );
}

/**
 * Собственно сама карта
 *
 *
 * @param props
 * @return {*}
 * @constructor
 */

type SvgGroupState = {
    image?: HTMLImageElement,
    scale: number,
}

function SvgGroup(props: any) {
    const mapImage = useSelector((s: Store) => s.companyInfo.map);
    const tablesList = useSelector((s: Store) => s.tablesList);
    const [state, setState] = useState<SvgGroupState>({scale: 1});

    if (state.image === undefined) {
        const image = new window.Image();
        image.src = mapImage;
        image.onload = () => {
            const scale = Math.min(props.width / image.width, props.height / image.height);
            setState({image, scale})
        };

        return <Preloader/>
    }

    return (
        <>
            <rect x={0} y={0} width={props.width} height={props.height} fill="transparent"/>
            <g
                transform={`translate(${(props.width - state.image.width * state.scale) / 2} ${(props.height - state.image.height * state.scale) / 2})`}>
                <g transform={`scale(${state.scale})`}>
                    <image id="image0"
                           xlinkHref={state.image.src}
                           width={state.image.width}
                           height={state.image.height}
                           x="0"
                           y="0"/>

                    {
                        tablesList.map(table => {
                            const options = {
                                selectedTable: props.selectedTable,
                                key: table.id,
                                table,
                                setSelectedTable: props.setSelectedTable,

                            };

                            if (props.availableTables.indexOf(table.id) === -1 && table.id !== props.order.table) {
                                return <DrawLockedTable {...options}/>
                            } else {
                                return <DrawAvailableTable {...options}/>
                            }
                        })
                    }

                </g>
            </g>
        </>
    )
}


/**
 * Отрисовка недоступных столиков
 *
 * @param props
 * @return {*}
 * @constructor
 */
function DrawLockedTable(props: any) {
    const {position} = props.table;

    const lock = {width: 14, height: 14};

    const rect = {
        x: 0,
        y: 0,
        width: position.width,
        height: position.height,
        rx: position.borderRadius.x,
        ry: position.borderRadius.y,
        fill: 'white',
        stroke: '#DFDFDF',
        strokeWidth: 2,
    };

    const pathProps: SVGProps<SVGPathElement> = {
        width: lock.width,
        height: lock.height,
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: '#DFDFDF',
    };


    return (
        <g transform={`translate(${position.x} ${position.y}) rotate(${position.rotate} ${position.width / 2} ${position.height / 2})`}
           className={`table is-disabled`}
        >
            <rect {...rect}/>

            <g transform={`translate(${(position.width - lock.width) / 2},${(position.height - lock.width) / 2})`}>
                {
                    <path {...pathProps}
                          d="M2.5 7V4.5C2.5 2.01472 4.51472 0 7 0C9.48528 0 11.5 2.01472 11.5 4.5V7H12C13.1046 7 14 7.89543 14 9V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V9C0 7.89543 0.895431 7 2 7H2.5ZM4 7V4.5C4 2.84315 5.34315 1.5 7 1.5C8.65685 1.5 10 2.84315 10 4.5V7H4Z"
                    />
                }
            </g>

        </g>
    )
}

function DrawAvailableTable(props: any) {
    const rect = props.table.position;

    function clickHandle() {
        props.setSelectedTable( props.table);
    }

    const groupProps = {
        transform: `translate( ${rect.x}, ${rect.y}) rotate(${rect.rotate} ${rect.width / 2} ${rect.height / 2})`
    };

    const rectProps = {
        x: 0,
        y: 0,
        rx: rect.borderRadius.x,
        ry: rect.borderRadius.y,
        width: rect.width,
        height: rect.height,
        fill: props.selectedTable === props.table ? '#1EC025' : 'white',
        stroke: '#00A807',
        strokeWidth: 2,
    };


    const textProps = {
        x: rect.width / 2,
        y: rect.height / 2,
        dominantBaseline: "middle",
        textAnchor: "middle",
        fontSize: 11,
        fill: 'white',
    };

    const activeShadowProps = {
        x: 0,
        y: 0,
        rx: rect.borderRadius.x,
        ry: rect.borderRadius.y,
        width: rect.width,
        height: rect.height,
        stroke: 'rgba(30, 192, 37, 0.6)',
        strokeWidth: 8,
    };


    return (
        <g {...groupProps} onClick={clickHandle}>
            {props.isActive && <rect {...activeShadowProps} className="shadow"/>}

            <rect {...rectProps} />

            <text {...textProps}>{props.table.number}</text>

        </g>
    )
}


export function InfoBlockIsFull() {
    return <div className="view-map__info is-locked">Столик занят</div>
}


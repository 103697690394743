import React from "react";

export const SvgDelete = () => <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M16 4H4C3.82272 4 3.65582 3.95325 3.51104 3.87146C3.20665 3.69951 3 3.37272 3 3C3 2.45 3.45 2 4 2H6.5L7.21 1.29C7.39 1.11 7.65 1 7.91 1H12.09C12.35 1 12.61 1.11 12.79 1.29L13.5 2H16C16.55 2 17 2.45 17 3C17 3.37272 16.7933 3.69951 16.489 3.87145C16.3442 3.95324 16.1773 4 16 4ZM15.7303 6C15.3831 5.40334 14.7364 5 14 5H6C5.26362 5 4.61686 5.40334 4.26974 6C4.09834 6.29462 4 6.63638 4 7V17C4 18.1 4.9 19 6 19H14C15.1 19 16 18.1 16 17V7C16 6.63638 15.9017 6.29462 15.7303 6ZM6 17H14V7H6V17Z"
          fill="currentColor"></path>
</svg>

export const SvgArrowLeft = () => <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M15 7H3.82998L8.70998 2.12C9.09998 1.73 9.09998 1.09 8.70998 0.700001C8.31998 0.310001 7.68998 0.310001 7.29998 0.700001L0.70998 7.29C0.31998 7.68 0.31998 8.31 0.70998 8.7L7.29998 15.29C7.68998 15.68 8.31998 15.68 8.70998 15.29C9.09998 14.9 9.09998 14.27 8.70998 13.88L3.82998 9H15C15.55 9 16 8.55 16 8C16 7.45 15.55 7 15 7Z"
        fill="currentColor"></path>
</svg>

export const SvgInfo = () => <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02945 4.02944 0 9 0C13.9706 0 18 4.02945 18 9ZM8 8C8 7.44772 8.44772 7 9 7C9.55228 7 10 7.44772 10 8V13C10 13.5523 9.55228 14 9 14C8.44772 14 8 13.5523 8 13V8ZM9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4Z"
          fill="currentColor"></path>
</svg>

export const SvgBird = () => <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M27.1728 24.1728C27.9227 23.4229 28.9395 23.0011 30 23C31.0605 23.0011 32.0773 23.4229 32.8272 24.1728C33.5771 24.9227 33.9989 25.9395 34 27C34 27.2652 33.8946 27.5196 33.7071 27.7071C33.5196 27.8947 33.2652 28 33 28C32.7348 28 32.4804 27.8947 32.2929 27.7071C32.1054 27.5196 32 27.2652 32 27C32 26.4695 31.7893 25.9609 31.4142 25.5858C31.0391 25.2107 30.5304 25 30 25C29.4696 25 28.9609 25.2107 28.5858 25.5858C28.2107 25.9609 28 26.4695 28 27C28 27.2652 27.8946 27.5196 27.7071 27.7071C27.5196 27.8947 27.2652 28 27 28C26.7348 28 26.4804 27.8947 26.2929 27.7071C26.1054 27.5196 26 27.2652 26 27C26.0011 25.9395 26.4229 24.9227 27.1728 24.1728Z"
        fill="currentColor"></path>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M44.7578 24.7574C44.6603 24.8547 44.584 24.9711 44.5338 25.0994C44.4836 25.2276 44.4604 25.3649 44.4659 25.5025C44.4726 25.6987 44.6739 30.331 46.8784 32.5356C47.9741 33.6319 49.1118 34.2422 50.2603 34.3491C50.3765 34.3604 50.4917 34.3659 50.606 34.3657C51.1258 34.3558 51.6381 34.2401 52.1116 34.0256C52.5852 33.8111 53.0101 33.5024 53.3604 33.1182C53.7106 32.7341 53.979 32.2826 54.149 31.7913C54.319 31.3 54.3871 30.7792 54.3491 30.2607C54.2417 29.1127 53.6314 27.9751 52.5346 26.8788C50.3305 24.6744 45.6988 24.4726 45.5024 24.4648C45.3648 24.4597 45.2277 24.4831 45.0995 24.5334C44.9714 24.5837 44.855 24.66 44.7578 24.7574ZM51.1211 28.293C51.8681 29.04 52.2961 29.7846 52.3574 30.4467C52.3812 30.7023 52.3459 30.9598 52.2541 31.1995C52.1623 31.4391 52.0166 31.6544 51.8281 31.8286C51.6553 32.0189 51.4402 32.1659 51.2002 32.2578C50.9602 32.3497 50.7019 32.384 50.4463 32.3578C49.7838 32.2964 49.04 31.8686 48.293 31.1216C47.2607 30.0894 46.7705 28.0112 46.5732 26.5716C48.0107 26.7676 50.085 27.2568 51.1211 28.293Z"
          fill="currentColor"></path>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.46487 26.8789C9.669 24.6744 14.3007 24.4727 14.4971 24.4649C14.6345 24.461 14.7712 24.4849 14.8991 24.5352C15.027 24.5854 15.1434 24.661 15.2414 24.7574C15.3388 24.8547 15.4151 24.9711 15.4653 25.0994C15.5156 25.2276 15.5387 25.3649 15.5332 25.5025C15.527 25.6981 15.3257 30.331 13.1208 32.5356C12.025 33.6319 10.8874 34.2422 9.73887 34.3491C9.62262 34.3602 9.50737 34.3658 9.39312 34.3658C8.87335 34.3558 8.36105 34.2401 7.88749 34.0256C7.41393 33.8111 6.98904 33.5024 6.63876 33.1182C6.28848 32.7341 6.02015 32.2826 5.85015 31.7913C5.68015 31.3 5.61205 30.7792 5.65 30.2607C5.75775 29.1125 6.36812 27.975 7.46487 26.8789ZM9.55375 32.3579C10.2159 32.2966 10.96 31.8687 11.707 31.1216C12.7431 30.0855 13.2324 28.0103 13.4289 26.5728C11.9893 26.7705 9.91113 27.2607 8.87887 28.293C8.13187 29.04 7.70412 29.7842 7.64262 30.4468C7.61875 30.7023 7.65408 30.9598 7.74586 31.1995C7.83765 31.4391 7.98343 31.6544 8.17188 31.8286C8.34502 32.0184 8.56015 32.1652 8.80008 32.257C9.04001 32.3489 9.2981 32.3835 9.55375 32.3579Z"
          fill="currentColor"></path>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M19 18C18.4067 18 17.8266 18.176 17.3333 18.5056C16.8399 18.8352 16.4554 19.3038 16.2284 19.8519C16.0013 20.4001 15.9419 21.0034 16.0576 21.5853C16.1734 22.1672 16.4591 22.7018 16.8787 23.1213C17.2982 23.5409 17.8328 23.8266 18.4147 23.9424C18.9967 24.0581 19.5999 23.9987 20.1481 23.7717C20.6962 23.5446 21.1648 23.16 21.4944 22.6667C21.8241 22.1733 22 21.5933 22 21C21.9991 20.2047 21.6828 19.4421 21.1203 18.8796C20.5579 18.3173 19.7954 18.0009 19 18ZM19 22C18.8022 22 18.6089 21.9413 18.4444 21.8315C18.28 21.7216 18.1518 21.5654 18.0761 21.3827C18.0004 21.2 17.9806 20.9989 18.0192 20.8049C18.0578 20.6109 18.153 20.4327 18.2929 20.2929C18.4327 20.153 18.6109 20.0578 18.8049 20.0192C18.9989 19.9807 19.2 20.0004 19.3827 20.0761C19.5654 20.1518 19.7216 20.28 19.8315 20.4445C19.9414 20.6089 20 20.8022 20 21C19.9997 21.2651 19.8943 21.5193 19.7068 21.7068C19.5193 21.8943 19.2651 21.9998 19 22Z"
          fill="currentColor"></path>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M39.3333 18.5056C39.8266 18.176 40.4067 18 41 18C41.7954 18.0009 42.5579 18.3173 43.1203 18.8796C43.6828 19.4421 43.9991 20.2047 44 21C44 21.5933 43.8241 22.1733 43.4944 22.6667C43.1648 23.16 42.6962 23.5446 42.148 23.7717C41.5999 23.9987 40.9967 24.0581 40.4147 23.9424C39.8328 23.8266 39.2982 23.5409 38.8787 23.1213C38.4591 22.7018 38.1734 22.1672 38.0576 21.5853C37.9419 21.0034 38.0013 20.4001 38.2284 19.8519C38.4554 19.3038 38.8399 18.8352 39.3333 18.5056ZM40.4444 21.8315C40.6089 21.9413 40.8022 22 41 22C41.2651 21.9998 41.5193 21.8943 41.7068 21.7068C41.8943 21.5193 41.9997 21.2651 42 21C42 20.8022 41.9414 20.6089 41.8315 20.4445C41.7216 20.28 41.5654 20.1518 41.3827 20.0761C41.2 20.0004 40.9989 19.9807 40.8049 20.0192C40.6109 20.0578 40.4327 20.153 40.2929 20.2929C40.153 20.4327 40.0578 20.6109 40.0192 20.8049C39.9806 20.9989 40.0004 21.2 40.0761 21.3827C40.1518 21.5654 40.28 21.7216 40.4444 21.8315Z"
          fill="currentColor"></path>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M0 36C0 19.6944 13.3819 0 30 0C46.6181 0 60 19.6944 60 36C60 51.0279 48.7851 60 30 60C11.2149 60 0 51.0279 0 36ZM2 36C2 49.7759 12.4678 58 30 58C47.5322 58 58 49.7759 58 36C58 20.6 45.5098 2 30 2C14.4902 2 2 20.6 2 36Z"
          fill="currentColor"></path>
</svg>

export const SvgSuccesOrder = ()=> <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M68.8287 19.6569L46.0003 42.4853L33.1719 29.6569L38.8287 24L46.0003 31.1716L63.1719 14L68.8287 19.6569Z" fill="#1EC025"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 69V37H18V65H38V69V73V85H36V73H12V85H10V73V69ZM90 37V69V73V85H88V73H64V85H62V73V69V65H82V37H90ZM28 52H72V54H51V85H49V54H28V52Z" fill="black"></path>
</svg>

export const SvgSelectBoxArrow = () => <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M2.55768 5C1.64737 5 1.2105 6.11736 1.8794 6.7348L5.82175 10.3739C6.20481 10.7275 6.79524 10.7275 7.17831 10.3739L11.1207 6.7348C11.7896 6.11736 11.3527 5 10.4424 5H2.55768Z"
        fill="currentColor"></path>
</svg>

import React from 'react';
import getGetParameters from "../utils/get-get-params";
import {SvgSuccesOrder} from "../assets/svg";
import moment from "moment";
import {declOfNum} from "../utils/utils";
import {ARRAY_GUESTS_TEXT} from "../constants";

const PaySuccess = () => {
    const props = getGetParameters();
    const mOrderDateTime = moment(props.orderStartTimeNormal)
    const mCurrentTime = moment(props.dateCurrent)
    const dateText = mOrderDateTime.clone().startOf('day').isSame(mCurrentTime) ? "Сегодня • " : "" +
        mOrderDateTime.format("DD MMM, dddd");

    const orderInfo: { title: string, value: string, marginBottom?: number }[] = [
        {title: "Дата", value: dateText},
        {title: "Время", value: mOrderDateTime.format("HH:mm")},
        {title: "Гости", value: `${props.bookingNumGuests} ${declOfNum(+props.bookingNumGuests, ARRAY_GUESTS_TEXT)}`},
        {title: "Столик", value: `${props.tableNumber}`, marginBottom: 20},
        {title: "Имя", value: `${props.clientName}`},
        {title: "Телефон", value: `${props.clientPhone.replace(/\+/g, " ")}`, marginBottom: 20},
        {title: "Депозит", value: `${props.bookingDeposit} ₽`},
    ];

    return (
        <div className="complete-order">
            <div className="complete-order__header">
                <div className="complete-order__icon"><SvgSuccesOrder/></div>
                <div className="complete-order__text-1">Депозит оплачен</div>
            </div>

            <div className="complete-order__body">
                <div className="company-info">
                    <div className="company-info__name">{props.companyName.replace(/\+/g, " ")}</div>
                    <div className="company-info__address">{props.companyAddress.replace(/\+/g, " ")}</div>
                </div>

                <div className="order">
                    {
                        orderInfo.map((item, key) => {
                            return <div className={`order__item ${item.marginBottom ? `mb-${item.marginBottom}` : ""}`}
                                        key={key}>
                                <div>{item.title}</div>
                                <div className="value">{item.value}</div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default PaySuccess

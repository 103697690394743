import moment from "moment";
import {ARRAY_HOURS_TEXT} from "../../../../../constants";
import {declOfNum} from "../../../../../utils/utils";


/**
 * Находим продолжительности бронирования
 * Если стол выбран - для текущего стола
 * Если стол не выбран берем максимальное среди всех столов
 *
 * @return {number}
 * @param props
 */
export function getOrderDurationVariants(props : any) {

    const momentOrderDate = moment(props.order.date), // moment время начала заказа
        dayOfWeek = momentOrderDate.format('e'), // день недели заказа
        workTime = props.companyInfo.workdays[dayOfWeek], // время работы заведения в текущий день
        momentEndWorkTime = momentOrderDate.clone().add(workTime.end_time, 's'), // moment конец работы заведения
        ordersOnDate = props.arBookings[props.order.date], // массив заказов на текущий день
        durations = []; // список продолжительности работы

    // moment начало текущей брони
    const mOrderStartTime = moment(props.order.date).add(props.order.time, 's');

    if (props.order.table) {
        // @ts-ignore
        const ordersList = ordersOnDate.filter(order => order.tableID === props.order.table);

        //стол выбран, генерируем для него инфу
        const duration = getDuration(
            mOrderStartTime,
            ordersList,
            momentEndWorkTime
        );

        durations.push(duration);
    } else {
        // гоним по всем столам
        for (let table of props.tablesList) {
            //стол выбран, генерируем для него инфу
            const duration = getDuration(
                mOrderStartTime,
                // @ts-ignore
                ordersOnDate.filter(order => order.tableID === table.id),
                momentEndWorkTime
            );

            durations.push(duration);
        }
    }

    const duration = Math.max(...durations)

    const durationVariants = getDurationVariants(duration , props.companyInfo.bookingDuration);

    return durationVariants;
}

/**
 * Находим продолжительность бронирования для текущего стола
 *
 * @param mOrderStartTime - moment начало заказа
 * @param ordersList - массив заказон на этот стол
 * @param momentEndWorkTime - moment окончание работы заведения
 * @returns {number} - продолжительность
 */
// @ts-ignore
function getDuration(mOrderStartTime, ordersList, momentEndWorkTime) {

    const result = [];

    for (let order of ordersList) {
        const duration = moment(order.dateStart).diff(mOrderStartTime, 'h', true);

        if (duration > 0) result.push(duration);
    }

    return (result.length === 0)
        ? momentEndWorkTime.diff(mOrderStartTime, 'h')
        : Math.min(...result)
}

/**
 * Собираем массив значений возможных продолжительностей
 *
 * @param duration
 * @param minDuration
 * @returns {[]}
 */
// @ts-ignore
function getDurationVariants(duration, minDuration) {
    const result = [];

    for (let i = minDuration; i<= duration; i++){
        result.push({title: i + declOfNum(i, ARRAY_HOURS_TEXT), value: i});
    }

    return result;
}

import React, {useEffect} from "react";
import api from "../../api";
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";


export interface IAuth {
    token: string
}

const Auth = () => {
    const token = useSelector((s : Store) => s.token)
    const dispatch = useDispatch();

    useEffect(() => {
        if (token === null) {
            const tokenName = process.env.REACT_APP_LOCALSTORAGE_TOKEN_NAME || "";
            const token = localStorage.getItem(tokenName);

            if (token === null) {
                // Получаем новый токен
                dispatch(api.user.getToken())

            } else {
                // Проверяем токен на валидность
                dispatch(api.user.UserWhoami(token))
            }
        }
    }, [token ]);

    return null;
}

export default Auth;

import {toFetch} from "../utils/utils";
import apiRoutes from "./apiRoutes";
import {actionsActiveDate} from "../redux/reducers/active-date";
import moment from "moment";
import {actionsCompanyInfo} from "../redux/reducers/company-info";
import {actionsTablesList} from "../redux/reducers/tables-list";
import {actionsSpecialEvents} from "../redux/reducers/special-events";
import {dateFormat} from "../constants";

export const companiesApi = {
    // Долучаем дату компании
    GetCompanyDate: (token: string) => {
        return (dispatch: any) => {
            const request = {
                "method": "GetCompanyDate",
                "token": token,
            };
            toFetch(apiRoutes.companies, request).then(r => r.json())
                .then(json => {
                    if (json.error) {
                        console.error(" Ошибка получения времени компании")
                    } else {
                        dispatch(actionsActiveDate.add({
                            activeDate: json.date,
                            currentTime: moment(json.date).add(json.offset, 's').format(dateFormat.full)
                        }));
                    }
                })
                .catch(error => console.error('Ошибка сервера'));
        }

    },

    /**
     * Получение данных о компании
     */

    getCompanyInfo: (token: string) => {
        return (dispatch: any) => {
            const request = {
                method: 'CompaniesList',
                token: token
            };

            toFetch(apiRoutes.companies, request).then(r => r.json())
                .then(json => {
                    if (json.error && json.hasOwnProperty('errorMessage')) {
                        console.error(json.errorMessage);
                    } else {
                        dispatch(actionsCompanyInfo.add(json.items[0]))
                    }
                })
                .catch(error => console.error(error))
            ;
        }
    },

    dailyTextEvents: {},

    /**
     * Получаем список событий на текущий день
     *
     * @param props
     * @constructor
     */
    DailyEventList: (props: { token: string, date: string }) => {
        const body = {
            "method": "DailyEventList",
            "token": props.token,
            "date_from": props.date,
            "date_to": props.date,
        };

        return toFetch(apiRoutes.dailyTextEvents, body).then(r => r.json())
    },

    /**
     * Получаем список столов в компании
     *
     * @param token
     * @constructor
     */
    GetTablesList: (token: string) => {
        return (dispatch: any) => {
            const request = {
                "method": 'TablesList',
                "token": token,
            };

            return toFetch(apiRoutes.tables, request)
                .then(res => res.json()).then(json => {
                    if (json.error === true) {
                        console.error(json.errorMessage);
                    } else {
                        dispatch(actionsTablesList.add(json.items))
                    }
                })
                .catch(error => console.error(error));
        }
    },

    /**
     *   Получаем данные о специальных мероприятиях (свадьба, банкет, пьянка)
     */
    GetSpecialEvents(token: string, currentDate: string) {
        return (dispatch : any) => {
            const mDay = moment(currentDate + 'T00:00:00');

            const request = {
                "method": "SpecsLockdownList",
                "token": token,
                "dateStart": mDay.format(dateFormat.short),
                "dateEnd": mDay.clone().add(30, 'd').format(dateFormat.short)
            };

            toFetch(apiRoutes.specialEvents, request).then(r => r.json())
                .then(json => {
                    if (json.error === true) {
                        console.error(json.errorMessage);
                    } else {
                        dispatch(actionsSpecialEvents.add(json.items))
                    }
                })
                .catch(error => console.error(error));
        }

    }
}

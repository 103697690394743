import React from "react";
import {SvgSuccesOrder} from "../../../../assets/svg";
import moment from "moment";
import {declOfNum} from "../../../../utils/utils";

import './complete.scss';
import {useSelector} from "react-redux";
import {Store} from "../../../../types/redux";
// @ts-ignore
export default function ViewComplete(props) {
    const companyInfo = useSelector((s: Store) => s.companyInfo);
    const tablesList = useSelector((s: Store) => s.tablesList);
    const {order} = props;

    const mOrderTime = moment(order.date).add(order.time, 's');

    const isCurrentDay = mOrderTime.clone().startOf('day').isSame(moment(props.activeDate)) ? 'Сегодня • ' : '';

    const dateText = `${isCurrentDay} ${mOrderTime.format('DD MMM, dddd')}`;
    const dateTime = mOrderTime.format('HH:mm');

    const guests = props.order.guests + declOfNum(props.order.guests, [" гость", ' гостя', ' гостей']);
    const table = tablesList.filter((item) => item.id === props.order.table)[0];

    const text2 = order.guests >= companyInfo.maxGuestCounter
        ? 'В рабочее время ресторана с Вами свяжется администратор'
        : 'Можете закрыть страницу.';

    const depositText = props.order.deposit > 0 ? props.order.deposit + ' ₽' : 'Без депозита';

    return (
        <div className='complete-order'>
            <div className="complete-order__header">
                <div className="complete-order__icon">
                    <SvgSuccesOrder/>
                </div>
                <div className="complete-order__text-1">
                    {
                        order.deposit === 0
                            ? "Ваш столик забронирован"
                            : order.guests >= companyInfo.maxGuestCounter ? "Ваш запрос принят" : "Cтолик забронирован"
                    }
                </div>
                <div className="complete-order__text-2">{order.deposit === 0 ? "" : text2}</div>
            </div>

            <div className="complete-order__body">
                <div className="company-info">
                    <div className="company-info__name">{props.companyInfo.name}</div>
                    <div className="company-info__address">{props.companyInfo.address}</div>
                </div>

                <div className="order">
                    <div className="order__item">
                        <div>Дата</div>
                        <div className="value">{dateText}</div>
                    </div>
                    <div className="order__item">
                        <div>Время</div>
                        <div className="value">{dateTime} (бронируется на {order.duration} часа)</div>
                    </div>
                    <div className="order__item">
                        <div>Гости</div>
                        <div className="value">{guests}</div>
                    </div>
                    <div className="order__item" style={{marginBottom: '20px'}}>
                        <div>Столик</div>
                        <div className="value">{table.number}</div>
                    </div>
                    <div className="order__item">
                        <div>Имя</div>
                        <div className="value">{props.order.clientName}</div>
                    </div>
                    <div className="order__item" style={{marginBottom: '20px'}}>
                        <div>Телефон</div>
                        <div className="value">{props.order.clientPhone}</div>
                    </div>

                    <div className="order__item">
                        <div>Депозит</div>
                        <div className="value">{depositText}</div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default function getGetParameters() {
    let result : {[key : string] : string} = {};
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            const tmp = item.split("=");
            result[tmp[0]] = decodeURIComponent(tmp[1]);
        });
    return result;
}

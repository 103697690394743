import React from 'react';
import ViewCompanyInfo from "./view-company-info";
import ViewDatePicker from "./view-date-picker";
import ViewTimePicker from "./view-time-picker";
import ViewNextButton from "./view-next-button";
import ViewEventCloseDay from "./view-event-close-day";
import ViewEventEndOfTime from "./view-event-end-of-time";
import ViewGuestCounter from "./view-guest-counter";
import ViewDuration from "./view-duration";
import {useSelector} from "react-redux";
import {Store} from "../../types/redux";
import ViewTableSelector from "./view-table-selector";

const Order20 = () => {
    const order = useSelector((s : Store) => s.order);

    const events : {[K : string] : boolean}= {
        closeDay : false, // Событие закрытия дня
        endOfTime : false, // Сегодня рабочий день закончился
        tableIsBooked : false, // Вы не успели забронировать столик (хотя бред - он же блокируется при выборе и потом никто не может его получить)

    };
    const hasEvent = !!Object.keys(events).find((K) => events[K]);

    return (
        <div className="order">
            <div className="order__title">Забронировать место</div>

            <ViewCompanyInfo/>

            <div className="order__body">
                <ViewDatePicker/>

                <ViewTimePicker/>

                { hasEvent && events.closeDay && <ViewEventCloseDay />}

                { hasEvent && events.endOfTime && <ViewEventEndOfTime />}

                { !hasEvent && <ViewGuestCounter />}

                { !hasEvent && /*order.time &&*/ <ViewDuration />}

                { !hasEvent && /*order.time && order.duration &&*/ <ViewTableSelector />}

            </div>

            <ViewNextButton/>
        </div>
    )
}

export default Order20;
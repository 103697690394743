import {SvgInfo} from "../../../../assets/svg";
import React from "react";

type Props = {
    text: string | null,
}

const EventInfo = (props: Props) => {
    const eventInfo = props.text || false;

    if (eventInfo === false) return null;

    return (
        <div className="event-info">
            <div className="event-info__icon"><SvgInfo/></div>
            <div className="event-info__text">{eventInfo}</div>
        </div>
    )
}

export default EventInfo;

import {combineReducers} from 'redux';
import token from "./reducers/token";
import activeDate from "./reducers/active-date";
import companyInfo from "./reducers/company-info";
import tablesList from "./reducers/tables-list";
import specialEvents from "./reducers/special-events";
import bookings from "./reducers/bookings";
import order from "./reducers/order";
import tablePricesByDay from "./reducers/table-prices-by-day";

const reducer = combineReducers({
    activeDate,

    bookings,

    companyInfo,

    order,

    specialEvents,

    tablePricesByDay,
    tablesList,
    token,
});

export default reducer;

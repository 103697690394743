import React, {useState} from "react";
import './map.scss';
import ViewMap, {InfoBlockIsFull} from "./map-view";
import moment from "moment";
import type {OrderViewProps} from "../../order-type";
import Preloader from "../../../preloader";
import {useSnackBar} from "../../../../context/snack-bar";
import {InfoBlock} from "../map.info-block";
import {ITable} from "../../../../types/table";
import {useSelector} from "react-redux";
import {Store} from "../../../../types/redux";
import {IOrder} from "../../../../types/order";
import api from "../../../../api";

export default function OrderMap(props: OrderViewProps & { order: IOrder }) {
    const token = useSelector((s: Store) => s.token);
    const tablesList = useSelector((s: Store) => s.tablesList);
    const dayOfWeek = +moment(props.order.date).format('e');
    const tablePricesByDay = useSelector((s: Store) => s.tablePricesByDay[props.order.date]);
    const snackBar = useSnackBar();

    const [selectedTable, setSelectedTable] = useState<ITable | undefined>(tablesList.find((T) => T.id === props.order.table));

    const customPriceForTable = selectedTable && tablePricesByDay && tablePricesByDay.find((i) => i.table === selectedTable.id);

    const deposit = customPriceForTable ? customPriceForTable.price : selectedTable && selectedTable.defaultDeposit[dayOfWeek];

    function setDataToOrder() {
        if (selectedTable !== undefined) {
            const order = {...props.order, table: selectedTable.id};
            order.deposit = customPriceForTable ? customPriceForTable.price : selectedTable && selectedTable.defaultDeposit[dayOfWeek];

            console.log('order, token',order, token);

            api.bookings.update(order, token)
                .then(r => {
                    if (r.error) {
                        snackBar.showError(r.errorMessage)
                    } else {
                        props.setOrder(r.result);
                        props.setViewPort('');
                    }
                });
            return <Preloader/>
        }
    }

    const classes: { [P: string]: any } = {
        root: {
            position: 'fixed',
            width: window.innerWidth,
            height: window.innerHeight,
            top: 0,
            left: 0,
            backgroundColor: 'white',
            zIndex: 20,
        }
    }

    const infoBlockOptions = {
        deposit,
        tableNumber: selectedTable?.name,
        numberOfPersons: `от ${selectedTable?.persons.from} до ${selectedTable?.persons.to} гостей`,
    }

    return (
        <div className="view-map" style={classes.root}>
            <div className="view-map__title">Выберите столик</div>
            <div className="view-map__body">
                <ViewMap
                    selectedTable={selectedTable}
                    setSelectedTable={setSelectedTable}
                    availableTables={props.availableTables}
                    order={props.order}
                />
            </div>
            {
                selectedTable === undefined
                    ? <InfoBlockIsFull/>
                    : <InfoBlock {...infoBlockOptions}/>
            }
            <div className="view-map__bottom">
                <div className="view-map__cancel" onClick={() => props.setViewPort('')}>Отмена</div>
                <div className={`view-map__apply ${selectedTable === undefined ? "is-disabled" : ""}`}
                     onClick={() => setDataToOrder()}>Выбрать
                </div>
            </div>
        </div>
    )
}


import {ITable} from "../../types/table";

const setTablesList = "setTablesList";


export const actionsTablesList = {
    add: (payload: any) => ({type: setTablesList, payload}),
}

const initialState: ITable[] = [];

export default function tablesList(state = initialState, action: any) {

    switch (action.type) {
        case setTablesList :
            return [...action.payload];
        default:
            return state;
    }
}

import React from 'react';
import {isIOS} from 'react-device-detect';
import {Provider} from 'react-redux';
import store from "./redux/store";

// ставим локаль moment.js для всего проекта
import moment from "moment";
import 'moment/locale/ru';     // ставим локаль moment.js для всего проекта
import './App.scss';
import {SnackBarProvider} from "./context/snack-bar";
import Auth from "./components/auth";
import CompanyTime from "./components/company-time";
import CompanyInfo from "./components/company-info";
import TablesList from "./components/tables-list";
import SpecialEvents from "./components/special-events";
import Websocket from "./components/websocket";
import AppRouter from "./router";
import TablePricesByDay from "./components/table-prieces-by-day";

// Отключаем перехват зума
if (isIOS) {
    document.addEventListener('touchmove', function (event) {
        // @ts-ignore
        if (event.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    let lastTouchEnd = 0;
    document.addEventListener('touchend', function (event) {
        let now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, false);
}

moment.locale('ru');

function App() {
    return <Provider store={store}>
        <div className="container">
            <SnackBarProvider>
                <Auth />
                <CompanyTime />
                <CompanyInfo/>
                <TablesList />
                <TablePricesByDay />
                <SpecialEvents/>
                <Websocket />

                <AppRouter />
            </SnackBarProvider>
        </div>
    </Provider>;
}

export default App;

const listTablePricesByDay = "listTablePricesByDay";

export const actionsTablePricesByDay = {
    update: (payload: any) => ({type: listTablePricesByDay, payload}),
}

const initialState = null;

export default function tablePricesByDay(state = initialState, action: {type : string, payload : any}) {

    switch (action.type) {
        case listTablePricesByDay :
            return {...action.payload};
        default:
            return state;
    }
}

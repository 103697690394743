import React from "react";
import {IOrderOptions} from "../../../order";
import OrderHasTable from "./order-has-table";
import OrderNotHasTable from "./order-not-has-table";

export default function ViewOrderTableSelector(props: IOrderOptions) {

    const isDisabled = !props.order.date || !props.order.time;

    if (props.order.guests && props.order.guests >= props.companyInfo.maxGuestCounter) {
        return (
            <div className="order-custom-table">
                К сожалению, по вашему запросу свободных столиков не найдено, вы можете оставить запрос на бронирование
                и с вами свяжется администратор в ближайшее доступное время
            </div>
        )
    }

    const available = props.availableTables.length;

    return (
        <div className={`order-table-select ${isDisabled ? "is-disabled" : ""}`}>
            <div className="order-table-select__header">
                <div className="order-table-select__title">Столик</div>
                <div className="order-table-select__available">{available} свободно</div>
            </div>
            {
                props.order.table === undefined || props.order.table === null ? <OrderNotHasTable {...props}/> : <OrderHasTable {...props}/>
            }
        </div>
    )
}

import React  from 'react';
import {useSelector} from "react-redux";
import {Store} from "../../types/redux";

const ViewCompanyInfo = ()=> {
    const companyInfo = useSelector((s : Store) => s.companyInfo);

    return (
        <div className="company-info">
            <div className={`company-info__icon ${companyInfo.detailPicture ==="" ? "" : "is-bg-transparent"}`}
                 style={{backgroundImage: `url(${companyInfo.detailPicture})`}}
            />
            <div className="company-info__text">
                <div className="company-info__title">{companyInfo.name}</div>
                <div className="company-info__address">{companyInfo.address}</div>
            </div>
        </div>
    )
}

export default ViewCompanyInfo;
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../types/redux";
import {actionsOrder} from "../redux/reducers/order";
import Preloader from "../components/preloader";
import Order20 from "../components/order-2.0";

const OrderPage = () => {
    const order = useSelector((s: Store) => s.order);
    const activeDate = useSelector((s: Store) => s.activeDate.activeDate);
    const companyInfo = useSelector((s: Store) => s.companyInfo);
    const dispatch = useDispatch();

    if (order === null) {
        dispatch(actionsOrder.new({date: activeDate, guests: 2, duration: companyInfo.bookingDuration}));
        return <Preloader/>
    }

    return <Order20/>
}

export default OrderPage;
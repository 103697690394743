import React from 'react'
import {useSelector} from "react-redux";
import {Store} from "../../types/redux";

const ViewNextButton = () => {
    const order = useSelector((s: Store) => s.order);
    const maxGuestCounter = useSelector((s: Store) => s.companyInfo.maxGuestCounter);

    function verifyOrder() {
        return !(!order.table && order.guests && order.guests < maxGuestCounter);
    }

    const isGoToNext = verifyOrder();

    const goToNext = () => {
        if (!isGoToNext) return null
        //Идем на страницу ввода данных о пользователе props.setViewPort('infoUser');
    }

    return (
        <div className="order__save-wrapper">
            <div className={`order__save ${isGoToNext ? '' : 'is-disabled'}`} onClick={goToNext}>Продолжить</div>
        </div>
    )
}

export default ViewNextButton
import React, {useMemo} from 'react';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";
import {IWorkDay} from "../../types/company";
import {actionsOrder} from "../../redux/reducers/order";
import api from "../../api";

type GetTimeGrid = {
    currentTime: string, // Текущее время
    orderDate: string, // День заявки
    workDay: IWorkDay, //Данные о рабочем дне
    bookingInterval: number, // Интервал бронирования
}

type GetTimeGridResult = {
    isDisabled: boolean,
    offset: number,
    rfc: string,
    timestamp: string,
    value: string,
}

const ViewTimePicker = ()=>{
    const dispatch = useDispatch();
    const activeDate = useSelector((s : Store) => s.activeDate);
    const order = useSelector((s : Store) => s.order);
    const companyInfo = useSelector((s : Store) => s.companyInfo);
    const dayOfWeek = +moment(order.date).format("e");
    const workDay = companyInfo.workdays[dayOfWeek];
    const token = useSelector((s : Store) => s.token);

    const setTime = (time : number) => {
        //Так как пока на бэке заказы лежат в столах и не возможности создавать заказ без ID стола - фактически удаяем текущий order
        if (order.id !== undefined) api.bookings.delete({token, id : order.id});

        dispatch(actionsOrder.update({...order, time, table: undefined, id: undefined }));
    }

    const getTimeGrid = () => {
        const result: GetTimeGridResult[] = [];

        const mCurrentTime = moment(activeDate.currentTime);
        const mDate = moment(order.date).startOf('day');
        const mStartWorkTime = mDate.clone().add(workDay.start_time, 's');
        const mTime = mStartWorkTime.clone();
        const mEndWorkTime = mDate.clone().add(workDay.end_time, 's');
        const TEN_HOURS = mDate.clone().add(22, 'h');

        while (mTime.isBefore(mEndWorkTime)) {

            const item: GetTimeGridResult = {
                value: mTime.format("HH:mm"),
                timestamp: mTime.format('x'),
                rfc: mTime.format(),
                offset: mTime.diff(mDate, 'seconds'), // Сдвиг от начала текущего дня в секундах
                isDisabled: mTime.isBefore(mCurrentTime) || mTime.isAfter(TEN_HOURS)// Если больше 22 часов - брокируем возможность забронировать
            };

            result.push(item);

            mTime.add(companyInfo.bookingInterval, 's'); // ОБЯЗАТЕЛЬНО!!!
        }

        return result;
    };

    const timeGrid = getTimeGrid();

    const currentTime = moment(activeDate.currentTime).format('x');

    return (
        <div className="order-time">
            <div className="order-time__title">Время</div>
            <ul>
                {
                    timeGrid.map(item => {
                        const isActive = (item.offset === order.time);
                        const isDisabled = currentTime > item.timestamp || item.isDisabled;

                        return <li key={item.value}
                            className={`${isActive ? "is-active" : ""} ${isDisabled ? "is-disabled" : ""}`}
                            onClick={()=> isDisabled ? null : setTime(item.offset)}
                        >
                            <span>{item.value}</span>
                        </li>
                    })}
            </ul>
        </div>
    )
}

export default ViewTimePicker;
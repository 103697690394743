import React from "react";
import {declOfNum} from "../../../../../utils/utils";
import './guests.scss';
import {isMobile} from "react-device-detect";
import {IOrderOptions} from "../../../order";

/**
 * Счетчик гостей
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function ViewOrderGuestsCounter(props: IOrderOptions) {
    const {guests} = props.order || 2;
    const {maxGuestCounter} = props.companyInfo;

    if (guests === undefined) return null;

    const countGuestText = guests + declOfNum(guests, [" гость", ' гостя', ' гостей']);

    const  isDisabled = (props.order.table !== null && props.order.table !== undefined) && props.order.guests &&  props.order.guests < maxGuestCounter;

    function updateValue(value: any) {
        if (!isDisabled) {
            value = value === 0 ? 1 : value;
            value = (isNaN(value)) ? 1 : value;

            props.setOrder({...props.order, guests: value, table: value >= maxGuestCounter ? "customTable" : undefined});
        }
    }

    const alertText = ((props.order.table === null || props.order.table === undefined) || (props.order.guests && props.order.guests >= maxGuestCounter))
        ? null
        : <div className="order-guest-counter__alert">Выбор количества гостей заблокирован. Чтобы снять блокировку -
            удалите выбранный столик</div>;

    return (
        <div
            className={`order-guest-counter ${(isDisabled) ? 'is-disabled' : ''}`}>
            <div className="order-guest-counter__wrapper">
                <div className="order-guest-counter__value">{countGuestText}</div>
                <div className="order-guest-counter__buttons">
                    {(isMobile)
                        ? <>
                            <div className="order-guest-counter__btn dec"
                                 onTouchEnd={() => updateValue(props.order.guests && props.order.guests - 1)}/>
                            <div className="order-guest-counter__btn inc"
                                 onTouchEnd={() => updateValue(props.order.guests && props.order.guests + 1)}/>
                        </>
                        : <>
                            <div className="order-guest-counter__btn dec"
                                 onMouseUp={() => updateValue(props.order.guests && props.order.guests - 1)}/>
                            <div className="order-guest-counter__btn inc"
                                 onMouseUp={() => updateValue(props.order.guests && props.order.guests + 1)}/>
                        </>
                    }
                </div>
            </div>

            {alertText}

        </div>
    )
}

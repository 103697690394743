import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";
import api from "../../api";
import moment from "moment";
import {actionsOrder} from "../../redux/reducers/order";
import {dateFormat} from "../../constants";
import {ISpecialEvent} from "../../types/special-events";

export type GetDatesGrid = Array<null | { title: string, value: string , event?: ISpecialEvent, activeDay : boolean}>;

const daysOfWeek = ["Пн","Вт","Ср","Чт","Пт","Сб","Вс"];

const ViewDatePicker = ()=>{
    const activeDate = useSelector((s: Store) => s.activeDate.activeDate);
    const token = useSelector((s: Store) => s.token);
    const order = useSelector((s: Store) => s.order);
    const specialEvents = useSelector((s: Store) => s.specialEvents);
    const dispatch = useDispatch();

    // Сетка дней
    const datesGrid = useMemo(() => {
        const result: GetDatesGrid = [];

        const mOrderDate = moment(order.date);
        const m = moment(activeDate).startOf('day');

        //Добавляем фэйковые даты ДО текущей даты
        result.length = +m.format('e');

        for (let i = 0; i < 30; i++) {

            const day = (i === 0) ? m : m.add(1, 'd');

            const value = day.format(dateFormat.short);

            result.push({
                title: day.format('DD'),
                value,
                event: specialEvents[value],
                activeDay : m.isSame(mOrderDate),
            })
        }

        // Добиваем фэйки до конца недели
        const countLastDates = 7 - (result.length % 7);

        result.length = result.length + countLastDates;

        return result;
    }, [activeDate,specialEvents, order.date]);

    // Надпись текущего дня
    const currentDayText = useMemo(()=>{
        const dateText = moment(order.date).format('DD MMM, dddd');

        return `${activeDate === order.date ? 'Сегодня • ' : ''}${dateText}`;
    }, [activeDate, order.date])

    const setDateToOrder = (date: string,) => {
        //Так как пока на бэке заказы лежат в столах и не возможности создавать заказ без ID стола - фактически удаяем текущий order
        if (order.id !== undefined) api.bookings.delete({token, id : order.id});

        dispatch(actionsOrder.update({...order, date, time: undefined, table: undefined, id: undefined }));
    }

    return (
        <div className="order-date">
            <div className="order-date__head">
                <div className="order-date__title">Дата</div>
                <div className="order-date__current-date">{currentDayText}</div>
            </div>
            <ul className={"order-date__week-day-names"}>
                {
                    daysOfWeek.map((i,k) => <li key={k}>{i}</li>)
                }
            </ul>
            <ul className="order-date__body">
                {
                    datesGrid.map((item, index) => {
                        if (item === null) return <li key={index}/>

                        return <li key={index}
                                   className={`${item?.activeDay ? "is-active" : ""} ${item?.event ? "is-special-event" : ""}`}
                                   onClick={() => setDateToOrder(item.value)}
                        >
                            <span>{item.title}</span>
                        </li>
                    })
                }
            </ul>

        </div>
    )
};

export default ViewDatePicker;
import {apiUser} from "./user";
import {companiesApi} from "./companies";
import bookingsApi from "./bookings";


const api = {
    bookings: bookingsApi,

    user: apiUser,

    companies: companiesApi,
}

export default api;

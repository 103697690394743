export function getCenterTouches(t1 : any, t2 : any) {
    return [
        (t1.pageX + t2.pageX) / 2,
        (t1.pageY + t2.pageY) / 2,
    ]

}
export function getLength(t1 : any, t2 : any) {
    return Math.sqrt((t1.pageX - t2.pageX) ** 2 + (t2.pageY - t2.pageY) ** 2);
}

import React, {useMemo} from "react";
import moment from "moment";
import {getDatesGrid} from "../../../order-container";
import {useSelector} from "react-redux";
import {Store} from "../../../../../types/redux";
import api from "../../../../../api";
import {IOrder} from "../../../../../types/order";

/**
 * Сетка дней
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function ViewOrderDate(props: any) {
    const activeDate = useSelector((s: Store) => s.activeDate.activeDate);
    const token = useSelector((s: Store) => s.token);

    // Сетка дней
    const datesGrid = useMemo(() => getDatesGrid(activeDate), [activeDate]);

    const setDateToOrder = (date: string,) => {
        //Так как пока на бэке заказы лежат в столах и не возможности создавать заказ без ID стола - фактически удаяем текущий order
        if (props.order.id !== undefined) api.bookings.delete({token, id : props.order.id});

        props.setOrder({...props.order, date, time: undefined, table: undefined, id: undefined });

        const viewportValue = (props.specialEvents[date] !== undefined) ? 'specialEvent' : '';
        props.setViewPort(viewportValue)
    }

    const dateText = moment(props.order.date).format('DD MMM, dddd');
    const currentDayText = `${activeDate === props.order.date ? 'Сегодня • ' : ''}${dateText}`;



    return (
        <div className="order-date">
            <div className="order-date__head">
                <div className="order-date__title">Дата</div>
                <div className="order-date__current-date">{currentDayText}</div>
            </div>
            <ul className={"order-date__week-day-names"}>
                <li>Пн</li>
                <li>Вт</li>
                <li>Ср</li>
                <li>Чт</li>
                <li>Пт</li>
                <li>Сб</li>
                <li>Вс</li>
            </ul>
            <ul className="order-date__body">
                {
                    datesGrid.map((item, index) => {
                        console.log('item',item,index);
                        if (!item) return <li key={index}/>;

                        const addClasses = [];

                        if (item.value === props.order.date) addClasses.push('is-active'); // текущий день
                        if (props.specialEvents[item.value] !== undefined) addClasses.push('is-special-event'); // есть спецСобытие

                        return (
                            <li key={index} className={addClasses.join(' ')} onClick={() => setDateToOrder(item.value)}>
                                <span>{item.title}</span>
                            </li>
                        )
                    })
                }
            </ul>

        </div>
    )
}

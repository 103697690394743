import React, {useState} from "react";
import OrderDurationView from "./order-duration-view";
import './order-duration.scss'
import {ARRAY_GUESTS_TEXT} from "../../../../../constants";
import {getOrderDurationVariants} from "./order-duration-controller";
import {declOfNum} from "../../../../../utils/utils";

// @ts-ignore
export default function OrderDuration(props) {

    const [isOpened, setIsOpened] = useState(false);

    const duration = props.order.duration;
    const durationText = duration + declOfNum(duration, ARRAY_GUESTS_TEXT);

    const durationVariants = getOrderDurationVariants(props);

    const options = {
        ...props,
        isOpened, setIsOpened,
        durationText,
        durationVariants,
    };

    return <OrderDurationView {...options}/>
}

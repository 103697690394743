import React, {useEffect, useState} from 'react';
import {IStaticInfo} from "../static-info";
import {useDispatch, useSelector} from "react-redux";
import {actionsBookings} from "../../redux/reducers/bookings";
import {Store} from "../../types/redux";
import {actionsActiveDate} from "../../redux/reducers/active-date";
import {TActiveDate} from "../../types/active-date";
import moment from "moment";
import {dateFormat} from "../../constants";

/**
 * Подключаем вэбсокеты чтобы слушать заказы на бронь
 */

const wsPoint = process.env.REACT_APP_WEBSOCKET_POINT as string | "";

const Websocket = () => {
    const dispatch = useDispatch();
    const token = useSelector((s: Store) => s.token);
    const [ws, setWs] = useState<WebSocket | null>(null);

    const check = () => {
        // @ts-ignore
        if (!ws || ws.readyState === WebSocket.CLOSED) connect(); //check if websocket instance is closed, if so call `connect` function.
    };

    const connect = (token: string) => {
        let ws = new WebSocket(wsPoint);
        let timeout = 250;
        let connectInterval: any;

        // websocket onopen event listener
        ws.onopen = () => {
            setWs(ws)

            ws.send(JSON.stringify({method: "authorize", args: token}));

            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };

        ws.onmessage = evt => {
            // listen to data sent from the websocket server
            const message = JSON.parse(evt.data);
            if (message.kind === "ClientBookingStateMessage") dispatch(actionsBookings.add(message.items))

            if (message.kind === 'CompanyTime' ) {
                const time : TActiveDate = {activeDate: moment(message.time).format(dateFormat.short), currentTime: message.time};
                dispatch(actionsActiveDate.add(time))
            }
        }

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (timeout + timeout) / 1000
                )} second.`,
                e.reason
            );

            timeout = timeout + timeout; //increment retry interval
            connectInterval = setTimeout(check, Math.min(10000, timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",    // @ts-ignore
                err.message,
                "Closing socket"
            );

            ws.close();
        };
    };


    useEffect(() => {
        if (token !== null) connect(token)
    }, [token]);

    return null;
}


// class Websocket extends React.Component {
//     private websocketPoint: string;
//     private dispatch: any;
//     private token: string | null;
//
//     constructor(props: IStaticInfo | Readonly<IStaticInfo>) {
//         super(props);
//         this.token = useSelector((s:Store) => s.token)
//
//         this.state = {
//             ws: null,
//         };
//
//         this.dispatch = useDispatch();
//
//         this.websocketPoint = process.env.REACT_APP_WEBSOCKET_POINT || ""
//     }
//
//     // single websocket instance for the own application and constantly trying to reconnect.
//
//     componentDidMount() {
//         this.connect();
//     }
//
//     timeout = 250; // Initial timeout duration as a class variable
//
//     /**
//      * @function connect
//      * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
//      */
//     connect = () => {
//         let ws = new WebSocket( this.websocketPoint);
//         let that = this; // cache the this
//         // @ts-ignore
//         let connectInterval;
//
//         // websocket onopen event listener
//         ws.onopen = () => {
//             // console.log("connected websocket main component");
//
//             this.setState({ws: ws});
//
//             ws.send(JSON.stringify({
//                 method: "authorize",
//                 args: this.props.token
//             }));
//
//             that.timeout = 250;     // @ts-ignore // reset timer to 250 on open of websocket connection
//             clearTimeout(connectInterval); // clear Interval on on open of websocket connection
//         };
//
//         ws.onmessage = evt => {
//             // listen to data sent from the websocket server
//             const message = JSON.parse(evt.data);
//             if (message.kind === "ClientBookingStateMessage") this.dispatch(actionsBookings.add(message.items))
//             // if (message.kind === "ClientBookingStateMessage"
//             //     && (JSON.stringify(message.items) !== JSON.stringify(this.state.arBookings))
//             // ) {
//             //     this.setState({arBookings: {...message.items}});
//             // }
//         }
//
//         // websocket onclose event listener
//         ws.onclose = e => {
//             console.log(
//                 `Socket is closed. Reconnect will be attempted in ${Math.min(
//                     10000 / 1000,
//                     (that.timeout + that.timeout) / 1000
//                 )} second.`,
//                 e.reason
//             );
//
//             that.timeout = that.timeout + that.timeout; //increment retry interval
//             connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
//         };
//
//         // websocket onerror event listener
//         ws.onerror = err => {
//             console.error(
//                 "Socket encountered error: ",    // @ts-ignore
//                 err.message,
//                 "Closing socket"
//             );
//
//             ws.close();
//         };
//     };
//
//     /**
//      * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
//      */
//     check = () => {
//         const {ws} = this.state;
//         // @ts-ignore
//         if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
//     };
//
//     render(){
//         return null
//     }
//
//     // render() {
//     //     if (isEmptyObject(this.state.arBookings)) {
//     //         return <Preloader/>
//     //     } else {
//     //         return <AppRouter {...this.props} arBookings={this.state.arBookings} />
//     //     }
//     // }
// }

export default Websocket;

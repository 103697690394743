import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";
import {toFetch} from "../../utils/utils";
import apiRoutes from "../../api/apiRoutes";
import moment from "moment";
import {actionsTablePricesByDay} from "../../redux/reducers/table-prices-by-day";
import {useSnackBar} from "../../context/snack-bar";

const TablePricesByDay = ()=> {
    const tablePricesByDay = useSelector((s : Store) => s.tablePricesByDay);
    const token = useSelector((s  : Store) => s.token);
    const activeDate = useSelector((s : Store) => s.activeDate);
    const dispatch = useDispatch();
    const snackBar = useSnackBar();

    if (activeDate === null) return null;

    const year = +moment(activeDate.activeDate).format("YYYY");

    if (tablePricesByDay === null) {
        const req = {
            "method":"PricesForDayList",
            token,
            year
        }

        toFetch(apiRoutes.tablePricesByDay,req)
            .then(r => r.json())
            .then(json => {
                if (json.error === false) {
                    dispatch(actionsTablePricesByDay.update(json.items))
                }  else {
                    snackBar.showError("Ошибка получения данных о специальных ценах с сервера")
                }
            })
    }

    return null;
}

export default TablePricesByDay;
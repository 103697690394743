import React from "react";
import type {OrderViewProps} from "../../order-type";
import {verifyOrder} from "../default/default-container";
import moment from "moment";
import './special-events.scss';
import ViewCompanyInfo from "../@share/company-info";
import ViewOrderDate from "../@share/date";
import {IOrderOptions} from "../../order";

export default function ViewSpecialEvent(props: IOrderOptions) {

    const isDisabled = verifyOrder(props.order, props.companyInfo.maxGuestCounter) ? '' : 'is-disabled';

    // Если кнопка не активна - можем нажать продолжить
    function clickHandler() {
        if (isDisabled === '') props.setViewPort('infoUser');
    }

    const specialEvent = {    // @ts-ignore
        dateText: moment(props.order.date).format('DD MMMM'),    // @ts-ignore
        reasonText: props.specialEvents[props.order.date].text,
    };

    return (
        <div className="order">
            <div className="order__title">Забронировать место</div>


            <ViewCompanyInfo{...props.companyInfo}/>

            <div className="order__body">

                <ViewOrderDate {...props} />

                <div className="special-event">
                    <div>{specialEvent.dateText}</div>
                    <div>ресторан не работает потому что</div>
                    <div>{specialEvent.reasonText}</div>
                </div>


            </div>

            <div className="order__save-wrapper">
                <div className={"order__save " + isDisabled} onClick={() => clickHandler()}>Продолжить</div>
            </div>
        </div>
    )
}

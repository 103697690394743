//@flow
import React, {useState} from "react";
import type {OrderMainProps} from "../../order-type";
import Preloader from "../../../preloader";
import moment from "moment";
import './sms-code.scss';
import {toFetch} from "../../../../utils/utils";
import SmsCodeSuccess from "./success";
import SmsCodeError from "./error";
import {useApiPoint} from "../../../../hooks/api-point";
import {IOrderOptions} from "../../order";


/**
 * Получаем код верификации
 * - Телефон верный и код получили
 * - Телефон с ошибкой - выводим информацию о том, что код не получили и что телефон возможно с ошибкой
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ViewSmsVerification(props: IOrderOptions) {
    const apiPoint = useApiPoint();

    const [state, setState] = useState({
        view: '',
    });

    switch (state.view) {
        case 'success':
            return <SmsCodeSuccess {...props} {...state} setState={setState}/>;
        case 'error':
            return <SmsCodeError {...props} {...state} setState={setState}/>;
        default:

            const request = {
                "method": "BookingSendVerificationCode", // @ts-ignore
                "token": props.token,    // @ts-ignore
                "phoneNumber": props.order.clientPhone,
            };

            toFetch(apiPoint + '/bookings', request).then(r => r.json())
                .then(json => {
                    if (json.error === true) {
                        setState({
                                view: 'error',    // @ts-ignore
                                error: json,
                            }
                        );
                    }

                    if (json.error === false) {

                        setState({
                            view: 'success',    // @ts-ignore
                            verify: {
                                code: json.code,
                                salt: json.salt,
                            },
                            timeoutHandler: moment(),
                        });
                    }
                });

            return <Preloader/>
    }
}

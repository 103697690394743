import React from "react";
import {verifyOrder} from "../default/default-container";
import {ViewEmptyInfo} from "./empty-view";

import './empty.scss';
import ViewCompanyInfo from "../@share/company-info";
import ViewOrderDate from "../@share/date/date";
import ViewOrderTime from "../@share/time/time";
import ViewOrderGuestsCounter from "../@share/guests/guests";

export default function ViewEmpty(props : any) {
    const isDisabled = verifyOrder(props.order, props.companyInfo.maxGuestCounter) ? '' : 'is-disabled';

    return (
        <div className="order">
            <div className="order__title">Забронировать место</div>


            <ViewCompanyInfo {...props.companyInfo}/>

            <div className="order__body">

                <ViewOrderDate {...props}/>

                <ViewOrderTime {...props}/>

                <ViewOrderGuestsCounter {...props}/>


                <ViewEmptyInfo time={props.order.time} order={props.order}/>

            </div>
            <div className="order__save-wrapper">
                <div className={"order__save " + isDisabled}>Продолжить</div>
            </div>
        </div>
    )
}

import React, {useEffect, useState} from "react";
import Preloader from "../preloader";
import StaticInfo from "../static-info";
import moment from "moment";
import {useApiPoint} from "../../hooks/api-point";
import api from "../../api";
import {IAuth} from "../auth";
import {useSnackBar} from "../../context/snack-bar";
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";

interface IActiveDate {
    activeDate: string, // Дата в формате YYYY-MM-DD
    currentTime: string, // Текущее время в секундах от начала дня
}

export interface ICompanyTime extends IAuth, IActiveDate {

}

const CompanyTime = () => {

    const dispatch = useDispatch();
    const token = useSelector((s: Store) => s.token);
    const activeDate = useSelector((s: Store) => s.activeDate);

    useEffect(() => {
        if (activeDate === null && token !== null) dispatch(api.companies.GetCompanyDate(token))
    }, [token,activeDate])

    return null;
}

export default CompanyTime

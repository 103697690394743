const apiPoint = process.env.REACT_APP_API_SERVER ? process.env.REACT_APP_API_SERVER : "";

const apiRoutes = {
    root: `${apiPoint}`,

    bookings : `${apiPoint}/bookings`,

    companies: `${apiPoint}/companies`,

    dailyTextEvents : `${apiPoint}/companies/daily-text-events`,

    specialEvents : `${apiPoint}/company/lockdown/special-services`,

    tables: `${apiPoint}/tables`,
    tablePricesByDay : `${apiPoint}/companies/prices-for-day`,

    users: `${apiPoint}/users`,
}

export default apiRoutes;

import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk'
import reducer from "./reducer";

const middleware = [
    reduxThunk,
];

const store = createStore(reducer, composeWithDevTools(
    applyMiddleware(...middleware),));


export default store;

import React from "react";

export default function ViewOrderComment(props: any) {
    return (
        <div className='order-comment'>
            <div className="order-comment__title">Пожелания</div>
            <div className="order-comment__body">
                <textarea value={props.order.comment}
                          onChange={e => props.setOrder({...props.order, comment: e.target.value})}/>
            </div>
        </div>
    )
}

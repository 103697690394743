import React, {createContext, useContext, useEffect, useState} from "react";
import './style.scss'

type TSnackBar = {
    show: boolean,
    text: string,
    color: "success" | "error"
}

interface ISnackBar {
    showError: (text: string) => void
    showSuccess: (text: string) => void
}


const SnackBarContext = createContext({} as ISnackBar);

export const SnackBarProvider = ({children}: any) => {
    const [state, setState] = useState<TSnackBar>({
        show: false,
        text: "",
        color: "success"
    });

    const showError = (text: string) => setState({show: true, text, color: "error"})

    const showSuccess = (text: string) => setState({show: true, text, color: "success"})

    useEffect(() => {
        if (state.show) setTimeout(() => {
            setState({...state, show: false})
        }, 1500)
    }, [state])

    return (
        <SnackBarContext.Provider value={{showError, showSuccess}}>
            {children}

            <div className={`ui-snackbar ui-snackbar_${state.color} ${state.show? "ui-snackbar_show" : ""}`}>{state.text}</div>

        </SnackBarContext.Provider>
    )
}


export const useSnackBar = () => {
    const snackBarContext = useContext(SnackBarContext)
    return snackBarContext;
}

import {declOfNum} from "../../../../utils/utils";
import moment from "moment";
import React from "react";

type Props = {
    tableNumber?: string,
    numberOfPersons?: string,
    deposit?: number,
}

export function InfoBlock({tableNumber = "Не задан", numberOfPersons = "Не задан", deposit = 0}: Props) {

    return <div className="view-map-info">
        <div className="view-map-info__left">
            <div className="view-map-info__table-number">{tableNumber} столик</div>
            <div className="view-map-info__max-guests">{numberOfPersons}</div>
        </div>


        <div className="view-map-info__right">
            {
                deposit > 0
                    ? <div className="view-map-info__deposit"><span> депозит </span> {deposit} ₽</div>
                    : <div className="view-map-info__deposit">Без депозита</div>
            }
        </div>
    </div>
}
import React, {useState} from 'react'
import {declOfNum} from "../../utils/utils";
import {ARRAY_GUESTS_TEXT, ARRAY_HOURS_TEXT} from "../../constants";
import {getOrderDurationVariants} from "../order/views/@share/order-duration/order-duration-controller";
import {SvgSelectBoxArrow} from "../../assets/svg";
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";
import moment from "moment";
import {actionsOrder} from "../../redux/reducers/order";

const ViewDuration = () => {
    const [isOpened, setIsOpened] = useState(false);
    const order = useSelector((s: Store) => s.order);
    const companyInfo = useSelector((s: Store) => s.companyInfo);
    const dispatch = useDispatch();

    const duration: number = order.duration || companyInfo.bookingDuration;
    const durationText = duration + declOfNum(duration, ARRAY_HOURS_TEXT);

    const getDurationVariants = () => {

        const result: { label: string, value: number }[] = [];

        const mDate = moment(order.date);
        const dayOfWeek = +mDate.format('e');
        const mStartTime = mDate.add(order.time, "s");
        const mEndTime = mStartTime.clone().startOf("day").add(companyInfo.workdays[dayOfWeek].end_time, "s");
        const maxDuration = mEndTime.diff(mStartTime, "hours");

        for (let i = companyInfo.bookingDuration; i <= maxDuration; i++) {
            result.push({label: i + declOfNum(i, ARRAY_HOURS_TEXT), value: i})
        }

        return result;
    }

    const setDuration = (value: number) => () => {
        dispatch(actionsOrder.update({...order, duration : value}))
        closeSelectBox();
    }

    const openSelectBox = () => setIsOpened(true);
    const closeSelectBox = () => setIsOpened(false);


    const durationVariants = getDurationVariants();

    return (
        <div className="order-duration">
            <div className="order-duration__title">Длительность</div>
            <div className={`order-duration__select-box select-box ${isOpened ? 'is-opened' : ''}`}>
                <div className="select-box__current" onClick={openSelectBox}>
                    <div className="select-box__value">{durationText}</div>
                    <div className="select-box__arrow"><SvgSelectBoxArrow/></div>
                </div>
                <ul className="select-box__values">
                    {
                        durationVariants.map((I, K) => {
                            return <li onClick={setDuration(I.value)} key={K}>{I.label}</li>
                        })
                    }
                </ul>
            </div>
        </div>
    )

}

export default ViewDuration


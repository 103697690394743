import {ICompanyInfo} from "../../types/company";

const setCompanyInfo = "setCompanyInfo";


export const actionsCompanyInfo = {
    add: (payload: any) => ({type: setCompanyInfo, payload}),
}

const initialState: ICompanyInfo | null = null;

export default function companyInfo(state = initialState, action: any) {

    switch (action.type) {
        case setCompanyInfo :
            return {...action.payload};
        default:
            return state;
    }
}

import React, {useEffect, useState} from 'react';
import moment from "moment";
import {declOfNum} from "../../utils/utils";
import {SvgSuccesOrder} from "../../assets/svg";
import {useParams, useHistory} from 'react-router-dom';
import Preloader from "../preloader";
import {useSnackBar} from "../../context/snack-bar";
import siteRoutes from "../../constants/siteRoutes";
import {IOrder} from "../../types/bookings";
import {ARRAY_GUESTS_TEXT} from "../../constants";
import {IData} from "../../types/__old-types";
import {useSelector} from "react-redux";
import {Store} from "../../types/redux";

type RouterParams = {
    orderId: string
}

const OrderComplete = () => {

    const props: IData = {
        activeDate: useSelector((s:Store) => s.activeDate),
        arBookings: useSelector((s:Store) => s.bookings),
        companyInfo: useSelector((s:Store) => s.companyInfo),
        specialEvents:useSelector((s:Store) => s.specialEvents),
        tablesList:  useSelector((s:Store) => s.tablesList),
        token: useSelector((s:Store) => s.token),
    }

    const params = useParams<RouterParams>();
    const [order, setOrder] = useState<IOrder | null>(null);
    const snackBar = useSnackBar();
    const history = useHistory();

    useEffect(() => {
        if (order === null) {
            const data = localStorage.getItem('order_' + params.orderId);

            if (data === null) {
                snackBar.showError('Заявка не найдена');
                history.push(siteRoutes.home)
            } else {
                setOrder(JSON.parse(data));
            }
        }
    }, [order, params.orderId]);

    if (order === null) return <Preloader/>

    const mOrderTime = moment(order.date).add(order.time, 's');

    // @ts-ignore
    const isCurrentDay = mOrderTime.clone().startOf('day').isSame(moment(props.activeDate.activeDate)) ? 'Сегодня • ' : '';

    const dateText = `${isCurrentDay} ${mOrderTime.format('DD MMM, dddd')}`;
    const dateTime = mOrderTime.format('HH:mm');

    const guests = order.guests + declOfNum(order.guests, ARRAY_GUESTS_TEXT);
    // @ts-ignore
    const table = props.tablesList.find((item) => item.id === order.table);

    return (
        <div className='complete-order'>
            <div className="complete-order__header">
                <div className="complete-order__icon">
                    <SvgSuccesOrder/>
                </div>
                <div className="complete-order__text-1">
                    {order.guests >= props.companyInfo.maxGuestCounter
                        ? "Ваш запрос принят"
                        : 'Столик забронирован'
                    }
                </div>
                <div className="complete-order__text-2">
                    {order.guests >= props.companyInfo.maxGuestCounter
                        ? 'В рабочее время ресторана с Вами свяжется администратор'
                        : 'Можете закрыть страницу.'}
                </div>
            </div>

            <div className="complete-order__body">
                <div className="company-info">
                    <div className="company-info__name">{props.companyInfo.name}</div>
                    <div className="company-info__address">{props.companyInfo.address}</div>
                </div>

                <div className="order">
                    <div className="order__item">
                        <div>Дата</div>
                        <div className="value">{dateText}</div>
                    </div>
                    <div className="order__item">
                        <div>Время</div>
                        <div className="value">{dateTime} (бронируется на {order.duration} часа)</div>
                    </div>
                    <div className="order__item">
                        <div>Гости</div>
                        <div className="value">{guests}</div>
                    </div>
                    <div className="order__item" style={{marginBottom: '20px'}}>
                        <div>Столик</div>
                        <div className="value">{
                            table
                                ? table.number
                                : "По согласованию с диспетчером"
                        }</div>
                    </div>
                    <div className="order__item">
                        <div>Имя</div>
                        <div className="value">{order.clientName}</div>
                    </div>
                    <div className="order__item" style={{marginBottom: '20px'}}>
                        <div>Телефон</div>
                        <div className="value">{order.clientPhone}</div>
                    </div>

                    <div className="order__item">
                        <div>Депозит</div>
                        <div className="value">{
                            order.deposit > 0 ? order.deposit + ' ₽' : 'Без депозита'
                        }</div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OrderComplete;

import React from "react";
import {SvgArrowLeft} from "../../../../../assets/svg";

type SmsCodeSuccessViewProps = {
    setViewPort: Function,
    errorText: string,
    order: Object,
    fieldCode: string,
    setFieldCode: Function,
    timeAlert: string,
    setState: Function, // Меняем состояние Блока проверки по СМС коду
    isValidate: boolean, // Прошел ли код проверку
    completeBooking: Function //Завершаем бронирование
};

export function SmsCodeSuccessView(props: SmsCodeSuccessViewProps) {
    return (
        <div className="view">
            <div className="view__header">
                <div className="icon" onClick={(() => props.setViewPort('confirmation'))}><SvgArrowLeft/></div>
                <div className="title">СМС подтверждение</div>
            </div>

            <div className="view__body notify">

                {props.errorText}

                <div className="notify__text">Введите код подтверждения, который мы отправили вам на
                    номер <br/><b>{    // @ts-ignore
                         props.order.clientPhone}</b></div>
                <div className="notify__code-field">
                    <input value={props.fieldCode} onChange={e => props.setFieldCode(e.target.value)}/>
                </div>

                {props.timeAlert}

            </div>
            <div className="view__footer">
                <div className="btn btn--grey" onClick={() => props.setState('')}>Отправить смс повторно</div>
                <SuccessButton isValidate={props.isValidate} completeBooking={props.completeBooking}/>
            </div>
        </div>
    )
}

/**
 * success button
 *
 * @param props
 * @returns {*}
 * @constructor
 */    // @ts-ignore
function SuccessButton(props) {
    if (props.isValidate)
        return <div className="btn btn--primary" onClick={props.completeBooking}>Подтвердить</div>;
    else
        return <div className="btn btn--primary is-disabled">Подтвердить</div>
}

import {ICompanyInfo} from "../../types/company";

const setSpecialEvents = "setSpecialEvents";


export const actionsSpecialEvents = {
    add: (payload: any) => ({type: setSpecialEvents, payload}),
}

const initialState: ICompanyInfo | null = null;

export default function specialEvents(state = initialState, action: any) {

    switch (action.type) {
        case setSpecialEvents :
            return {...action.payload};
        default:
            return state;
    }
}

import moment from 'moment';
import type {OrderMainProps} from "../../order-type";
import {dateFormat} from "../../../../constants";
import {IOrder} from "../../../../types/order";

/**
 * Собираем календарь
 *
 * @param currentDate
 * @return {[]}
 */
export function getOrderDates(currentDate : any) {
    let result = [];

    const momentCurrentDay = moment(currentDate).startOf('day');

    const datOfWeek = momentCurrentDay.format('e');

    //Добавляем фэйковые даты ДО текущей даты
    // @ts-ignore
    for (let i = 0; i < datOfWeek; i++) {
        result.push({});
    }

    // Вычисляем 14 дней вперед
    for (let i = 0; i < 14; i++) {

        const day = (i === 0) ? momentCurrentDay : momentCurrentDay.add(1, 'd');

        result.push({
            title: day.format('DD'),
            value: day.format(dateFormat.short),
        })
    }

    // Добиваем фэйки до конца недели
    const countLastDates = 7 - (result.length % 7);

    if (countLastDates < 7) {
        for (let i = 0; i < countLastDates; i++) {
            result.push({});
        }
    }
    return result;
}

/**
 * Собираем рабочее время заведения
 *
 * @param date
 * @param startTime
 * @param endTime
 * @param bookingInterval
 * @return {[]}
 */
export function calculateWorkTime(props: OrderMainProps) {


    // @ts-ignore
    const m = moment(props.act).startOf('day'), //moment текущего дня
        // @ts-ignore
        {workdays, bookingInterval} = props.companyInfo,
        dateOfWeek = m.format('e'), // день недели
        startTime = workdays[dateOfWeek].start_time, // Начало работы заведения в сек от начала дня
        endTime = workdays[dateOfWeek].end_time, // Конец работы заведения в сек от начала дня
        momentCurrentTime = m.clone().add(startTime, 's'), // moment начала работы
        momentEndTime = m.clone().add(endTime, 's'), // moment конец работы
        result = [];

    while (momentCurrentTime < momentEndTime) {
        result.push({
            value: momentCurrentTime.format("HH:mm"),
            timestamp: momentCurrentTime.format('x'),
            rfc: momentCurrentTime.format(dateFormat.full),
        });

        momentCurrentTime.add(+bookingInterval, 's'); // ОБЯЗАТЕЛЬНО!!!
    }

    //отрубаем последние часы сетки, в которые забронировать низзя
    // @ts-ignore
    const countDisabledItems = props.companyInfo.bookingDuration / (props.companyInfo.bookingInterval / 3600) - 1;
    for (let i = result.length - countDisabledItems; i < result.length; i++) {
        // @ts-ignore
        result[i]['isDisabled'] = true;
    }

    return result;
}

/**
 * Проверяем заказ на заполнение
 */

export function verifyOrder(order : IOrder, maxGuestCounter : number) {

    if (order.guests && order.guests >= maxGuestCounter) {
        return order.time;
    }

    return !(!order.table && order.guests && order.guests < maxGuestCounter);
}

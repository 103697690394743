import {TActiveDate} from "../../types/active-date";

const setActiveDate = "setActiveDate";


export const actionsActiveDate = {
    add: (payload: TActiveDate) => ({type: setActiveDate, payload}),
}

const initialState: TActiveDate | null = null;

export default function activeDate(state = initialState, action: any) {

    switch (action.type) {
        case setActiveDate :
            return {...action.payload};
        default:
            return state;
    }
}

import React from "react";
import {SvgSelectBoxArrow} from "../../../../../assets/svg";
import {ARRAY_HOURS_TEXT} from "../../../../../constants";
import {declOfNum} from "../../../../../utils/utils";

export default function OrderDurationView(props : any) {

    const durationText = props.order.duration + declOfNum(props.order.duration, ARRAY_HOURS_TEXT);

    return (
        <div className="order-duration">
            <div className="order-duration__title">Длительность</div>
            <div className={`order-duration__select-box select-box ${props.isOpened ? 'is-opened' : ''}` }>
                <div className="select-box__current" onClick={() => props.setIsOpened(!props.isOpened)}>
                    <div className="select-box__value">{durationText}</div>
                    <div className="select-box__arrow"><SvgSelectBoxArrow/></div>
                </div>
                <ul className="select-box__values">
                    {
                        // @ts-ignore
                        props.durationVariants.map(item => <SelectBoxItem item={item} key={item.title} {...props}/>)
                    }
                </ul>
            </div>
        </div>
    )
}

const SelectBoxItem = (props : any)=> {

    function clickHandler(){
        props.setOrder({...props.order, duration: props.item.value});
        props.setIsOpened(!props.isOpened);
    }

    if (props.item.value === props.order.duration) return null;

    return (
        <li onClick={clickHandler}>{props.item.title}</li>
    )
};

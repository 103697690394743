import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../types/redux";
import api from "../../api";

/**
 * Получение данных о компании с сервера
 * @constructor
 */
const CompanyInfo = () => {
    const dispatch = useDispatch();
    const token = useSelector((s: Store) => s.token);
    const companyInfo = useSelector((s: Store) => s.companyInfo)

    useEffect(() => {
        if (companyInfo === null && token) dispatch(api.companies.getCompanyInfo(token))
    }, [dispatch, companyInfo, token])

    return null;
}

export default CompanyInfo;
